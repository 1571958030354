.number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1px;
  opacity: 0;
  transition: opacity ease-in 0.5s;
}
.number-inview {
  opacity: 1;
}
.number-container * {
  padding: 0;
  margin: 0;
  text-align: center;
}
.number-container .number {
  font-size: 5rem;
  line-height: 5rem;
  transform: translateX(4rem);
}
.number-container .number-caption {
  font-size: 1.5rem;
  line-height: 1.5rem;
  white-space: nowrap;
  transform: translateX(-4rem);
}
.number-inview .number,
.number-inview .number-caption {
  transform: translateX(0);
  transition: transform ease-in 0.5s;
}
