.stat-section {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 2560px;
  margin: 0 auto;
  padding: 3rem 7% 0;
  align-items: flex-end;
  justify-content: space-between;
}

.stat-section .stat-img-container {
  display: flex;
  align-items: flex-end;
  width: 70%;
}
.stat-section .stat-img-container img {
  width: 100%;
  min-width: 240px;
  max-width: 1920px;
  z-index: 10;
  height: auto;
}
.stat-section .stats-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
  padding: 3rem 1rem;
}

@media screen and (max-width: 1320px) {
  .stat-section .stats-container .number-container .number {
    font-size: 4rem;
    line-height: 4rem;
  }
  .stat-section .stats-container {
    gap: 2rem;
    padding: 2rem 1rem;
  }
}
@media screen and (max-width: 1080px) {
  .stat-section .stats-container .number-container .number {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
  .stat-section .stats-container {
    gap: 1.5rem;
  }
}
@media screen and (max-width: 880px) {
  .stat-section {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
  .stat-section .stat-img-container {
    align-self: flex-start;
    width: 100%;
  }
  .stat-section .stats-container .number-container .number {
    font-size: 5rem;
    line-height: 5rem;
  }
}
