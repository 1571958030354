.about-section {
  display: grid;
  justify-items: center;
  width: 86%;
  max-width: 1020px;
  gap: 3rem;
  margin: 0 auto;
  padding-bottom: 6rem;
  grid-template-columns: 0.8fr 1.2fr;
  grid-template-rows: 1fr 0.1fr 0.1fr 1fr;
  background: url('../../images/background/Logo\ Shadow.png') no-repeat right
    top;
  background-size: 60% auto;
}

.about-section * {
  margin: 0;
}
.about-section .sample-container {
  grid-column: 1;
  grid-row: 1 / 5;
  min-width: 140px;
  align-self: flex-start;
}
.about-section .sample-container img {
  width: 100%;
}

.about-section .about-pars {
  font-size: 1.25rem;
}
.about-section .about-heading {
  align-self: end;
  font-size: 2.5rem;
  font-weight: 300;
}
.about-section .link-btn {
  align-self: start;
  justify-self: start;
}
@media screen and (max-width: 840px) {
  .about-section {
    row-gap: 2rem;
  }
  .about-section .about-heading {
    font-size: 2rem;
  }
  .about-section .about-pars {
    font-size: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .about-section {
    grid-template-rows: 0.5fr 1fr 0.1fr 0.1fr;
  }
  .about-section .sample-container {
    grid-row: 1/3;
  }
  .about-section .about-heading {
    align-self: end;
    font-size: 1.5rem;
  }
  .about-section .par2,
  .about-section .link-btn {
    grid-column: 1/3;
    justify-self: center;
  }
  .about-section .link-btn {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .about-section {
    gap: 1.7rem;
  }
  .about-section .about-pars {
    font-size: 0.8rem;
  }
}
