.page-container {
  position: relative;
  padding-bottom: 4rem;
}
.page-container .bg-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 4rem;
  background-color: #f6f7f8;
  z-index: -1;
  overflow: hidden;
}
.page-container .bg-container::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 480px;
  height: 480px;
  background: url('../../images/background/Logo\ Shadow.png') no-repeat left;
  background-size: auto 100%;
}
.career-page {
  position: relative;
  margin: -17rem auto 0;
  padding: 3rem 4rem;
  width: min(850px, 94%);
  display: flex;
  background-color: #fff;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.3);
  z-index: 2;
  border-radius: 1rem;
  align-items: flex-start;
  gap: 2rem;
}
.career-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.career-form .input-field {
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
  padding: 1rem 0.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: #000;
  resize: none;
  transition: border ease-in-out 0.3s;
}
.career-form .input-field:focus {
  border-color: #ffc905;
}
.career-form .input-field::placeholder {
  color: inherit;
  opacity: 1;
}

.career-form .form-sbmt {
  align-self: center;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid #000;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  transition: ease-in-out 0.3s;
  transition-property: border, color;
}
.career-form .form-sbmt:hover {
  color: #ffc905;
  border-color: #ffc905;
}
@media screen and (max-width: 991px) {
  .career-page {
    margin-top: -10rem;
  }
}

@media screen and (max-width: 720px) {
  .career-page > img {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .career-page {
    padding: 2rem;
  }
  .career-form {
    gap: 1rem;
  }
  .career-form .input-field {
    font-size: 1rem;
    padding: 0.75rem 0.5;
  }
  .career-form .form-sbmt {
    margin-top: 1rem;
    font-size: 1rem;
  }
}
