.our-map-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
  padding: 3rem 0;
  max-width: 2560px;
  margin: 0 auto;
}
.our-map-section .map-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.our-map-section .map-container img {
  width: 100%;
}
.our-map-section * {
  margin: 0;
}

.our-map-section h2 {
  font-size: 2.5rem;
}
.our-map-section h2,
.our-map-section h3 {
  margin: 0 7%;
  text-transform: capitalize;
}
.our-map-section h3 {
  font-weight: 300;
  font-size: 2rem;
}
.our-map-section h3 span {
  font-weight: 500;
}
