* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.bold {
  font-weight: 700;
}
.gold {
  color: #ffc905;
}
.link-btn {
  padding: 0.5em 2em;
  border: 3px solid #1c1d1d;
  color: #1c1d1d;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  transition: font-weigth ease-in-out 200ms;
}
.link-btn:hover {
  color: #ffc905;
  font-weight: 600;
  border-color: #ffc905;
}
img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.project-imgs-carousel .owl-stage {
  display: flex;
  align-items: center;
}

form {
  position: relative;
}

form.sent::after,
form.failed::after {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  width: 100%;
  font-size: 0.75rem;
}
form.sent::after {
  content: 'Your Info was sent, We will contact you soon!';
  color: #4bb543;
}
form.failed::after {
  content: 'Some error occured, try again!';
  color: #ff3333;
}

.overlay-bx-2.light-bg {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (min-width: 992px) {
  .stretch-children-height,
  .fill-height,
  .fill-height div {
    display: flex !important;
  }
  img.full-height {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
/*=======================================
  || CSS TABLE CONTENT ||  
=======================================

=======================================
1. GENERAL 
=======================================

=======================================
2. HEADING & TITLE 
=======================================

=======================================
3. TEXT & PARAGRAPH 
=======================================

	-------------------------------------
	1. text align
	-------------------------------------
	2. text vertical align
	-------------------------------------
	3. text no line brack
	-------------------------------------
	4. text transform
	-------------------------------------
	5. text size
	-------------------------------------
	6. text bold

======================================
4. LISTING STYLES 
======================================

	-------------------------------------
	1. listing before icon color css
	-------------------------------------
	2. listing before numbers
	-------------------------------------

======================================
5. BOX SPACING (Padding & Margin)
======================================

	-------------------------------------
	1.1 padding (around)
	-------------------------------------
	1.2 Padding (top)
	-------------------------------------
	1.3 Padding (bottom)
	-------------------------------------
	1.4 Padding (left)
	-------------------------------------
	1.5 Padding (right)
	-------------------------------------
	1.6 Padding (left right)
	-------------------------------------
	1.7 Padding (top bottom)
	-------------------------------------
	2.1 margin (around)
	-------------------------------------
	2.2 marging (top)
	-------------------------------------
	2.3 marging (bottom)
	-------------------------------------
	2.4 marging (left)
	-------------------------------------
	2.5 marging (right)
	-------------------------------------
	2.6 marging (left right)
	-------------------------------------
	2.7 marging (top bottom)
	-------------------------------------
	2.8 colum gap less
	-------------------------------------

======================================
6. POSITIONS ( absolute , relative , statice & z-index)
======================================

	-------------------------------------
	1. position
	-------------------------------------
		1.1 relative		
		1.2 absolute		
		1.3 static	
	-------------------------------------
	2. z-index
	-------------------------------------

======================================
7. COLOR
======================================

	-------------------------------------
	1. text
	-------------------------------------
	2. background color
	-------------------------------------
	3. background image
	-------------------------------------

======================================
8. OVERLAY ( transparient box style  )
======================================

	use for box
	left right part css

======================================
9. BOXES CSS 
======================================

	-------------------------------------
	1. box content
	-------------------------------------
	2. For icons with box
	-------------------------------------
	3. Border
	-------------------------------------
	4. For only icons
	-------------------------------------
	5. place icon with box
	-------------------------------------
	6. icon box left align
	-------------------------------------
	7. icon box right align
	-------------------------------------
	8. icon box center align
	-------------------------------------
	9. media with content box css
	-------------------------------------
	10. for images
	-------------------------------------

======================================
10. IMAGES OVERLAY EFFECTS (overly animation on images)  
======================================

	-------------------------------------
	1. effect 1
	-------------------------------------
	2. Gradient-black
	-------------------------------------
	3. Gradient-white
	-------------------------------------

======================================
11. shortcodeS 
======================================

	-------------------------------------
	1. Buttons 
	------------------------------------- 
		1.1 theme default button 		
		1.2 button text uppercase		
		1.3 button size 		
		1.4 button rounded		
		1.6 white 		
		1.7 black 		
		1.8 Gray 		
		1.9 pink 		
		1.10 Blue		
		1.11 Green		
		1.12 Orange		
		1.13 Red 		
		1.14 Brown 
		1.15 Yellow 
		1.16 purple 
		1.17 Graphical 
		1.19 Outline 
		1.20 Outline white  
		1.21 Outline black 
		1.22 Outline gray 
		1.23 Outline pink 
		1.24 Outline blue 
		1.25 Outline green 
		1.26 Outline orange 
		1.27 Outline red 
		1.28 Outline brown 
		1.29 Outline yellow 
		1.30 Outline purple 
		1.31 Outline hover 
		1.32 button text link 
		1.33 text link white 
		1.34 text link black 
		1.35 text link Gray 
		1.36 text link pink 
		1.37 text link Blue 
		1.38 text link Green 
		1.39 text link Orange 
		1.40 text link Red 
		1.41 text link Brown 
		1.42 text link Yellow 
		1.43 text link purple 
		1.44 app strore 
		1.45 filter buttons css 
		1.45(1) Masonry filter alignment [Left-Right-Center] 
		1.45(2) Masonry filter link style 
		1.45(3) Masonry filter button style 
		1.45(4) Masonry filter button outline style 
	-------------------------------------
	2. Title separators 
	-------------------------------------
		Separator defualt		
		Separator liner		
		Separator square		
		Separator icon
	-------------------------------------
	3. Deviders 
	-------------------------------------
	4. TABS 
	-------------------------------------
		4.1 tabs style 1 [ default ] 		
		tabs nav center 		
		4.2 tabs bg  [ tabs background ] 		
		4.3 tabs bg  [ tabs background ]		
		4.4 tabs bg & top border  [ tabs background & top border in active ]		
		4.4 tabs style 2 [ content with border outer ]		
		4.5 tabs style 3  [ left-nav ] 		
		4.6 tabs style 4  [ left-nav & content with border ] 		
		4.7 tabs style 5  [ right-nav ] 		
		4.8 tabs style 6  [ right-nav & content with border ]
	-------------------------------------
	5. Accordians 
	-------------------------------------
	Accodian open close indicator css
		1. Accordion defult		
		2. Accordion outline		
		3. Accordion bg gray		
		4. Accordion bg primary		
		5. Accordion bg dark
	-------------------------------------
	6. Carousels 
	-------------------------------------
		6.1 Client carousel 1
	-------------------------------------
	7. Testimonials 
	-------------------------------------
		7.1 testimonial with background image
		7.2 testimonial 6
	-------------------------------------
	8. Pricing table
	-------------------------------------
		8.1 Pricing table
		8.2 pricing table style 2
	-------------------------------------
	9. Alert box 
	-------------------------------------
	10. Image effects 
	-------------------------------------
		10.1 image-opacity 
		10.2 image-zoom 
		10.3 image-zoom-slow 
		10.4 image-shrink 
		10.5 image-side-pan 
		10.6 image-vertical-pan 
		10.7 image-fade-in 
		10.8 image-fade-out 
		10.9 image-rotate 
		10.10 image-sepia 
		10.11 image-blurr 
		10.12 image-blurr-invert 
		10.13 image off color 
		10.14 image on color 
	-------------------------------------
	11. Modal pop 
	-------------------------------------
	12. Social icons 
	-------------------------------------
		12.1 social icon default 
		12.2 social icon with border 
		12.3 social icon dark 
		12.4 social share icon style 1 
	-------------------------------------
	13. Breadcrumb 
	-------------------------------------
		13.1 breadcrumb-row-1
		13.2 Breadcrumb-1 
	-------------------------------------
	14. Google map 
	-------------------------------------
	16. Countdown clock 
	-------------------------------------
	17. Form elements 
	-------------------------------------  
		17.1 input group 
		17.3 radio & checkbox 
		17.4 Browse Button css   
		17.8 validation symbol 
		17.9 error for select box 
		17.10 succes for select box 
		17.11 textarea 
			17.11(1) resize-vertical
			17.11(2) resize-horizontal
			17.11(3) resize-none
	-------------------------------------
	18. Loading 
	-------------------------------------  
		18.1 loading - 1
		18.2 loading - 2
	-------------------------------------
	19. Widgets 
	------------------------------------- 
		19.1 widget listing		
		19.2 widget search custom		
		19.3 widget search wp-default		
		19.4 widget recent-posts		
		19.8 widget tags-list		
		19.14 widget gallery		
		19.18 widget client slider 
	-------------------------------------
	20. Table 
	-------------------------------------
	21. Video 
	-------------------------------------
======================================
12. WP DEFAULT  
======================================

======================================
13. BLOG  
====================================== 

	-------------------------------------
	3. if no image with blog post
	-------------------------------------
	10. side bar
	-------------------------------------
	11. Blog single
	-------------------------------------
	12. comment list
	-------------------------------------
	13. comment form
	-------------------------------------

======================================
14. HEADER (top part of page)
======================================

	1. header full screen
	-------------------------------------
	2. without top bar
	------------------------------------- 
	3. map page header
	-------------------------------------

======================================
16. LOGO ( company identify  symbol )
======================================

	1. logo for header 
	-------------------------------------
	2. when header is sticky 
	
======================================
17. MAIN BAR ( place logo & main-navigation )
======================================

	main bar with box view
	-------------------------------------
	1. Top Part Search Bar

======================================
18. NAVIGATIONS 
======================================

	-------------------------------------
	1. mena-menu
	-------------------------------------
	2. nav dark version
	-------------------------------------
	3. when header is sticky
	-------------------------------------  
	4. when header is sticky
	-------------------------------------  
	5. header id Fixed
	-------------------------------------
	6. header with bg primary color 1
	-------------------------------------
======================================
19. BANNER
======================================

	-------------------------------------
	1. inner page banner 
	-------------------------------------

======================================
20. SLIDER
======================================

	-------------------------------------
	1. owl slider 
	-------------------------------------
		1.1  next pre btn
		1.2 owl dots button
		1.3 owl button top to center
		1.4 owl button top to left
		1.5 owl button top to right
		1.6 owl button bottom to center [it is default position ]
		1.7 owl button bottom to left
		1.8 owl button bottom to right
		1.9 owl button vertical to center
		1.10 owl button show on hover
		1.11 owl slider button
		1.12 owl dots nav position

======================================
21. PAGINATION 
======================================

	1. pagination style-1 

======================================
22. FOOTER 
======================================

	-------------------------------------
	1. footer fixed on bottom css
	-------------------------------------
		1.1 footer fixed with fullscreen view
		1.2 footer fixed with boxed view
	-------------------------------------
	5. widget Getintuch
	-------------------------------------
	6. scroll top btn css
	-------------------------------------

======================================
23. PAGE-CONTENT
======================================

	-------------------------------------
	1. Section Head Part 
	-------------------------------------
	2. Section Content Part 
	-------------------------------------

======================================
24. AFTER-LOGIN-CONTENT
======================================

	-------------------------------------
	2. Contact us page 
	-------------------------------------

======================================
25. THEMEFOREST REQUIRED CLASSES
======================================

	-------------------------------------
	1. Text meant only for screen readers
	-------------------------------------

======================================
27. BOX MAX-WIDTH css (  )
======================================

======================================
28. SHOP & PRODUCT DETAIL (Woocommerce)
======================================

	-------------------------------------
	1. Product Box 
	-------------------------------------

======================================
29. content show on image box () 
======================================

 	info box show 

======================================
30. Customize  Grouping css 
======================================

	-------------------------------------
	1.set-radius 
	------------------------------------- 
	2. transition 
	------------------------------------- 
	3. float-clearfix 
	-------------------------------------

======================================
32. Inner pages 
======================================
	------------------------------------- 
	2. Out Team
	-------------------------------------
		2.6. Out Team six
	------------------------------------- 
	4. product block
	------------------------------------- 
======================================
33. Background Effects
======================================

======================================
34. Home Page
======================================

======================================
35. Carousel overlay content
======================================

======================================
36. Overlay Box 2
======================================

======================================
37. line-amiation-block
======================================

======================================
38. latest-blog-dark
======================================

======================================
39. background Square block
======================================

======================================
40. About us
======================================

======================================
41. Our Services
======================================

======================================
42. Contact Us
======================================

======================================
43. Work carousal
======================================

======================================
44. Work Masonry
======================================

======================================
45. our-story
======================================
*/

/*
=======================================
3. TEXT & PARAGRAPH 
=======================================*/

/*-------------------------------------
	1. text align
------------------------------------ */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

/*-------------------------------------
	2. text vertical align
------------------------------------ */
.v-align-t {
  vertical-align: top;
}
.v-align-m {
  vertical-align: middle;
}
.v-align-b {
  vertical-align: bottom;
}

/*-------------------------------------
	3. text no line brack
------------------------------------ */
.text-nowrap {
  white-space: nowrap;
}

/*-------------------------------------
	4. text transform
------------------------------------ */
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/*-------------------------------------
	5. text size
------------------------------------ */
.font-12 {
  font-size: 12px;
  line-height: 18px;
}
.font-14 {
  font-size: 14px;
  line-height: 20px;
}
.font-16 {
  font-size: 16px;
  line-height: 22px;
}
.font-18 {
  font-size: 18px;
  line-height: 24px;
}
.font-20 {
  font-size: 20px;
  line-height: 26px;
}
.font-22 {
  font-size: 22px;
  line-height: 28px;
}
.font-24 {
  font-size: 24px;
  line-height: 30px;
}
.font-26 {
  font-size: 26px;
  line-height: 32px;
}
.font-28 {
  font-size: 28px;
  line-height: 34px;
}
.font-30 {
  font-size: 30px;
  line-height: 36px;
}
.font-32 {
  font-size: 32px;
  line-height: 38px;
}
.font-34 {
  font-size: 34px;
  line-height: 40px;
}
.font-36 {
  font-size: 36px;
  line-height: 42px;
}
.font-38 {
  font-size: 38px;
  line-height: 44px;
}
.font-40 {
  font-size: 40px;
  line-height: 46px;
}
.font-50 {
  font-size: 50px;
  line-height: 56px;
}
.font-60 {
  font-size: 60px;
  line-height: 66px;
}
.font-70 {
  font-size: 70px;
  line-height: 76px;
}
.font-80 {
  font-size: 80 px;
  line-height: 86px;
}

/*-------------------------------------
	6. text bold
------------------------------------ */
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-900 {
  font-weight: 900;
}

/*-------------------------------------
	7. Letter Spacing
------------------------------------ */
.letter-spacing-2 {
  letter-spacing: 2px;
}
.letter-spacing-3 {
  letter-spacing: 3px;
}
.letter-spacing-4 {
  letter-spacing: 4px;
}
.letter-spacing-5 {
  letter-spacing: 5px;
}
/*======================================
4. LISTING STYLES 
======================================*/

dl,
ul,
ol {
  list-style-position: outside;
  padding: 0;
}
ul,
ol {
  margin-bottom: 24px;
}

ul li,
ol li {
  padding: 0;
}

dl {
  margin-left: 0;
  margin-bottom: 30px;
}
dl dd {
  margin-bottom: 10px;
}
.list-simple li {
  margin-bottom: 10px;
}

.list-simple li ul {
  margin-left: 15px;
  margin-top: 10px;
}

.list-simple.list-unstyled li ul {
  margin-left: 30px;
  margin-top: 10px;
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
  padding: 5px 5px 5px 20px;
  position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
  font-family: 'FontAwesome';
  position: absolute;
  left: 0;
  top: 3px;
  display: block;
  font-size: 15px;
  color: #000;
}

.list-circle li:before {
  top: 6px;
  font-size: 10px;
}

.list-circle li:before {
  content: '\f111';
}
.list-angle-right li:before {
  content: '\f105';
}
.list-arrow li:before {
  content: '\f0da';
}
.list-check li:before {
  content: '\f00c';
}
.list-checked li:before {
  content: '\f046';
}
.list-check-circle li:before {
  content: '\f058';
}
.list-chevron-circle li:before {
  content: '\f138';
}
.list-arrow-circle li:before {
  content: '\f0a9';
}
.list-times-circle li:before {
  content: '\f057';
}

/*-------------------------------------
	1. listing before icon color css
-------------------------------------*/
ul.primary li:before {
  color: #ffbc13;
}
ul.secondry li:before {
  color: #77c04b;
}
ul.black li:before {
  color: #000;
}
ul.white li:before {
  color: #fff;
}
ul.orange li:before {
  color: #ff6600;
}
ul.green li:before {
  color: #00cc00;
}
ul.red li:before {
  color: #ff3737;
}

dl.no-margin,
ul.no-margin,
ol.no-margin {
  margin: 0px !important;
  list-style: none;
}

/*-------------------------------------
	2. listing before numbers
-------------------------------------*/
ol.list-num-count {
  counter-reset: li;
  padding-left: 0;
}
ol.list-num-count > li {
  position: relative;
  margin: 0 0 6px 30px;
  padding: 4px 8px;
  list-style: none;
}
ol.list-num-count > li:before {
  content: counter(li, decimal);
  counter-increment: li;
  position: absolute;
  top: 0;
  left: -28px;
  width: 28px;
  height: 28px;
  padding: 5px;
  color: #fff;
  background: #000;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
}
ol.list-num-count.lower-alpha > li:before {
  content: counter(li, lower-alpha);
}
ol.list-num-count.upper-alpha > li:before {
  content: counter(li, upper-alpha);
}
ol.list-num-count.list-outline > li:before {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}
ol.list-num-count.list-outline-none > li:before {
  background-color: transparent;
  color: #000;
}

/*======================================
5. BOX SPACING (Padding & Margin)
======================================*/

/*-------------------------------------
	1.1 padding (around)
-------------------------------------*/
.p-a0 {
  padding: 0;
}
.p-a5 {
  padding: 5px;
}
.p-a10 {
  padding: 10px;
}
.p-a15 {
  padding: 15px;
}
.p-a20 {
  padding: 20px;
}
.p-a25 {
  padding: 25px;
}
.p-a30 {
  padding: 30px;
}
.p-a40 {
  padding: 40px;
}
.p-a50 {
  padding: 50px;
}
.p-a60 {
  padding: 60px;
}
.p-a70 {
  padding: 70px;
}
.p-a80 {
  padding: 80px;
}
.p-a90 {
  padding: 90px;
}
.p-a100 {
  padding: 100px;
}
.p-a110 {
  padding: 110px;
}
.p-a120 {
  padding: 120px;
}
.p-a130 {
  padding: 130px;
}
.p-a140 {
  padding: 140px;
}
.p-a150 {
  padding: 150px;
}

/*-------------------------------------
	1.2 Padding (top)
-------------------------------------*/
.p-t0 {
  padding-top: 0;
}
.p-t5 {
  padding-top: 5px;
}
.p-t10 {
  padding-top: 10px;
}
.p-t15 {
  padding-top: 15px;
}
.p-t20 {
  padding-top: 20px;
}
.p-t30 {
  padding-top: 30px;
}
.p-t40 {
  padding-top: 40px;
}
.p-t50 {
  padding-top: 50px;
}
.p-t60 {
  padding-top: 60px;
}
.p-t70 {
  padding-top: 70px;
}
.p-t80 {
  padding-top: 80px;
}
.p-t90 {
  padding-top: 90px;
}
.p-t100 {
  padding-top: 100px;
}
.p-t110 {
  padding-top: 110px;
}
.p-t120 {
  padding-top: 120px;
}
.p-t130 {
  padding-top: 130px;
}
.p-t140 {
  padding-top: 140px;
}
.p-t150 {
  padding-top: 150px;
}

/*-------------------------------------
    1.3 Padding (bottom)
-------------------------------------*/
.p-b0 {
  padding-bottom: 0;
}
.p-b5 {
  padding-bottom: 5px;
}
.p-b10 {
  padding-bottom: 10px;
}
.p-b15 {
  padding-bottom: 15px;
}
.p-b20 {
  padding-bottom: 20px;
}
.p-b30 {
  padding-bottom: 30px;
}
.p-b40 {
  padding-bottom: 40px;
}
.p-b50 {
  padding-bottom: 50px;
}
.p-b60 {
  padding-bottom: 60px;
}
.p-b70 {
  padding-bottom: 70px;
}
.p-b80 {
  padding-bottom: 80px;
}
.p-b90 {
  padding-bottom: 90px;
}
.p-b100 {
  padding-bottom: 100px;
}
.p-b110 {
  padding-bottom: 110px;
}
.p-b120 {
  padding-bottom: 120px;
}
.p-b130 {
  padding-bottom: 130px;
}
.p-b140 {
  padding-bottom: 140px;
}
.p-b150 {
  padding-bottom: 150px;
}

/*-------------------------------------
	1.4 Padding (left)
-------------------------------------*/
.p-l0 {
  padding-left: 0;
}
.p-l5 {
  padding-left: 5px;
}
.p-l10 {
  padding-left: 10px;
}
.p-l15 {
  padding-left: 15px;
}
.p-l20 {
  padding-left: 20px;
}
.p-l30 {
  padding-left: 30px;
}
.p-l40 {
  padding-left: 40px;
}
.p-l50 {
  padding-left: 50px;
}
.p-l60 {
  padding-left: 60px;
}
.p-l70 {
  padding-left: 70px;
}
.p-l80 {
  padding-left: 80px;
}
.p-l90 {
  padding-left: 90px;
}
.p-l100 {
  padding-left: 100px;
}
.p-l110 {
  padding-left: 110px;
}
.p-l120 {
  padding-left: 120px;
}
.p-l130 {
  padding-left: 130px;
}
.p-l140 {
  padding-left: 140px;
}
.p-l150 {
  padding-left: 150px;
}

/*-------------------------------------
	1.5 Padding (right)
-------------------------------------*/
.p-r0 {
  padding-right: 0;
}
.p-r5 {
  padding-right: 5px;
}
.p-r10 {
  padding-right: 10px;
}
.p-r15 {
  padding-right: 15px;
}
.p-r20 {
  padding-right: 20px;
}
.p-r30 {
  padding-right: 30px;
}
.p-r40 {
  padding-right: 40px;
}
.p-r50 {
  padding-right: 50px;
}
.p-r60 {
  padding-right: 60px;
}
.p-r70 {
  padding-right: 70px;
}
.p-r80 {
  padding-right: 80px;
}
.p-r90 {
  padding-right: 90px;
}
.p-r100 {
  padding-right: 100px;
}
.p-r110 {
  padding-right: 110px;
}
.p-r120 {
  padding-right: 120px;
}
.p-r130 {
  padding-right: 130px;
}
.p-r140 {
  padding-right: 140px;
}
.p-r150 {
  padding-right: 150px;
}

/*-------------------------------------
	1.6 Padding (left right)
-------------------------------------*/
.p-lr0 {
  padding-left: 0;
  padding-right: 0;
}
.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}
.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}
.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}
.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}
.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}
.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}
.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}
.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}
.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}
.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}
.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}
.p-lr120 {
  padding-left: 120px;
  padding-right: 120px;
}
.p-lr150 {
  padding-left: 150px;
  padding-right: 150px;
}

/*-------------------------------------
	1.7 Padding (top bottom)
-------------------------------------*/
.p-tb0 {
  padding-bottom: 0;
  padding-top: 0;
}
.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}
.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}
.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}
.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}
.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}
.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}
.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}
.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}
.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}
.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}
.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}
.p-tb120 {
  padding-bottom: 120px;
  padding-top: 120px;
}
.p-tb150 {
  padding-bottom: 150px;
  padding-top: 150px;
}

/*-------------------------------------
	2.1 margin (around)
-------------------------------------*/
.m-a-1 {
  margin: -1px;
}
.m-a0 {
  margin: 0;
}
.m-a5 {
  margin: 5px;
}
.m-a10 {
  margin: 10px;
}
.m-a15 {
  margin: 15px;
}
.m-a20 {
  margin: 20px;
}
.m-a30 {
  margin: 30px;
}
.m-a40 {
  margin: 40px;
}
.m-a50 {
  margin: 50px;
}
.m-a60 {
  margin: 60px;
}
.m-a70 {
  margin: 70px;
}
.m-a80 {
  margin: 80px;
}
.m-a90 {
  margin: 90px;
}
.m-a100 {
  margin: 100px;
}
.m-a110 {
  margin: 110px;
}
.m-a120 {
  margin: 120px;
}
.m-a130 {
  margin: 130px;
}
.m-a140 {
  margin: 140px;
}
.m-a150 {
  margin: 150px;
}

/*-------------------------------------
	2.2 marging (top)
-------------------------------------*/
.m-t0 {
  margin-top: 0;
}
.m-t5 {
  margin-top: 5px;
}
.m-t10 {
  margin-top: 10px;
}
.m-t15 {
  margin-top: 15px;
}
.m-t20 {
  margin-top: 20px;
}
.m-t30 {
  margin-top: 30px;
}
.m-t40 {
  margin-top: 40px;
}
.m-t50 {
  margin-top: 50px;
}
.m-t60 {
  margin-top: 60px;
}
.m-t70 {
  margin-top: 70px;
}
.m-t80 {
  margin-top: 80px;
}
.m-t90 {
  margin-top: 90px;
}
.m-t100 {
  margin-top: 100px;
}
.m-t110 {
  margin-top: 110px;
}
.m-t120 {
  margin-top: 120px;
}
.m-t130 {
  margin-top: 130px;
}
.m-t140 {
  margin-top: 140px;
}
.m-t150 {
  margin-top: 150px;
}

/*-------------------------------------
	2.3 marging (bottom)
-------------------------------------*/
.m-b0 {
  margin-bottom: 0;
}
.m-b5 {
  margin-bottom: 5px;
}
.m-b10 {
  margin-bottom: 10px;
}
.m-b15 {
  margin-bottom: 15px;
}
.m-b20 {
  margin-bottom: 20px;
}
.m-b30 {
  margin-bottom: 30px;
}
.m-b40 {
  margin-bottom: 40px;
}
.m-b50 {
  margin-bottom: 50px;
}
.m-b60 {
  margin-bottom: 60px;
}
.m-b70 {
  margin-bottom: 70px;
}
.m-b80 {
  margin-bottom: 80px;
}
.m-b90 {
  margin-bottom: 90px;
}
.m-b100 {
  margin-bottom: 100px;
}
.m-b110 {
  margin-bottom: 110px;
}
.m-b120 {
  margin-bottom: 120px;
}
.m-b130 {
  margin-bottom: 130px;
}
.m-b140 {
  margin-bottom: 140px;
}
.m-b150 {
  margin-bottom: 150px;
}

/*-------------------------------------
	2.4 marging (left)
-------------------------------------*/
.m-l0 {
  margin-left: 0;
}
.m-l5 {
  margin-left: 5px;
}
.m-l10 {
  margin-left: 10px;
}
.m-l15 {
  margin-left: 15px;
}
.m-l20 {
  margin-left: 20px;
}
.m-l30 {
  margin-left: 30px;
}
.m-l40 {
  margin-left: 40px;
}
.m-l50 {
  margin-left: 50px;
}
.m-l60 {
  margin-left: 60px;
}
.m-l70 {
  margin-left: 70px;
}
.m-l80 {
  margin-left: 80px;
}
.m-l90 {
  margin-left: 90px;
}
.m-l100 {
  margin-left: 100px;
}
.m-l110 {
  margin-left: 110px;
}
.m-l120 {
  margin-left: 120px;
}
.m-l130 {
  margin-left: 130px;
}
.m-l140 {
  margin-left: 140px;
}
.m-l150 {
  margin-left: 150px;
}

/*-------------------------------------
	2.5 marging (right)
-------------------------------------*/
.m-r0 {
  margin-right: 0;
}
.m-r5 {
  margin-right: 5px;
}
.m-r10 {
  margin-right: 10px;
}
.m-r15 {
  margin-right: 15px;
}
.m-r20 {
  margin-right: 20px;
}
.m-r30 {
  margin-right: 30px;
}
.m-r40 {
  margin-right: 40px;
}
.m-r50 {
  margin-right: 50px;
}
.m-r60 {
  margin-right: 60px;
}
.m-r70 {
  margin-right: 70px;
}
.m-r80 {
  margin-right: 80px;
}
.m-r90 {
  margin-right: 90px;
}
.m-r100 {
  margin-right: 100px;
}
.m-r110 {
  margin-right: 110px;
}
.m-r120 {
  margin-right: 120px;
}
.m-r130 {
  margin-right: 130px;
}
.m-r140 {
  margin-right: 140px;
}
.m-r150 {
  margin-right: 150px;
}

/*-------------------------------------
	2.6 marging (left right)
-------------------------------------*/
.m-lr0 {
  margin-left: 0;
  margin-right: 0;
}
.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}
.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}
.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}
.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}
.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}
.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}
.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}
.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}
.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}
.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}
.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}
.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}
.m-lr120 {
  margin-left: 120px;
  margin-right: 120px;
}
.m-lr150 {
  margin-left: 150px;
  margin-right: 150px;
}

/*-------------------------------------
	2.7 marging (top bottom)
-------------------------------------*/
.m-tb0 {
  margin-bottom: 0;
  margin-top: 0;
}
.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}
.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}
.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}
.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}
.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}
.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}
.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}
.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}
.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}
.m-tb120 {
  margin-bottom: 120px;
  margin-top: 120px;
}
.m-tb150 {
  margin-bottom: 150px;
  margin-top: 150px;
}

/*-------------------------------------
	2.8 colum gap less
-------------------------------------*/
.no-col-gap [class*='col-xs-'],
.no-col-gap [class*='col-sm-'],
.no-col-gap [class*='col-md-'],
.no-col-gap [class*='col-lg-'] {
  padding-left: 0;
  padding-right: 0;
}

/*======================================
6. POSITIONS ( absolute , relative , statice & z-index)
======================================*/

/*-------------------------------------
	1. position
-------------------------------------*/
/*1.1 relative*/
.relative {
  position: relative;
}
/*1.2 absolute*/
.absolute {
  position: absolute;
}
/*1.3 static*/
.static {
  position: static;
}

/*-------------------------------------
	2. z-index
-------------------------------------*/
.z-index1 {
  z-index: 1;
}
.z-index2 {
  z-index: 2;
}
.z-index3 {
  z-index: 3;
}
.z-index4 {
  z-index: 4;
}
.z-index5 {
  z-index: 5;
}
.z-index6 {
  z-index: 6;
}
.z-index7 {
  z-index: 7;
}
.z-index8 {
  z-index: 8;
}
.z-index9 {
  z-index: 9;
}
.z-index10 {
  z-index: 10;
}
.z-index100 {
  z-index: 100;
}
.z-index999 {
  z-index: 999;
}

/*======================================
7. COLOR
======================================*/

/*-------------------------------------
	1. text
-------------------------------------*/
.text-white {
  color: #fff;
}
.text-primary {
  color: #000;
}
.text-secondry {
  color: #efefef;
}
.text-black {
  color: #000;
}
.text-gray {
  color: #f5f6f6;
}
.text-gray-dark {
  color: #d3d3d3;
}
.text-red {
  color: #99090a;
}
.text-green {
  color: #557131;
}
.text-yellow {
  color: #d5dd02;
}
.text-light-blue {
  color: #004f83;
}
/*-------------------------------------
	2. background color
-------------------------------------*/
.bg-transparent {
  background-color: transparent;
}
.bg-primary {
  background-color: #000;
}
.bg-secondry {
  background-color: #efefef;
}
.bg-white {
  background-color: #fff;
}
.bg-black {
  background-color: #000;
}
.bg-black-light {
  background-color: #212121;
}
.bg-gray {
  background-color: #f6f7f8;
}
.bg-gray-light {
  background-color: #f1f1f1;
}
.bg-gray-dark {
  background-color: #d3d3d3;
}
.bg-red {
  background-color: #fb5455;
}
.bg-green {
  background-color: #a5de5c;
}
.bg-yellow {
  background-color: #ffc905;
}
.bg-dark-blue {
  background-color: #161b23;
}
.bg-light-blue {
  background-color: #3097db;
}

/*-------------------------------------
	3. background image
-------------------------------------*/
.bg-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .bg-parallax {
    background-attachment: fixed !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/*======================================
8. OVERLAY ( transparient box style  )
======================================*/

.overlay-wraper {
  position: relative;
}
.overlay-main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.opacity-01 {
  opacity: 0.1;
}
.opacity-02 {
  opacity: 0.2;
}
.opacity-03 {
  opacity: 0.3;
}
.opacity-04 {
  opacity: 0.4;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-06 {
  opacity: 0.6;
}
.opacity-07 {
  opacity: 0.7;
}
.opacity-08 {
  opacity: 0.8;
}
.opacity-09 {
  opacity: 0.9;
}

.overlay-light .overlay-main {
  opacity: 0.3;
}
.overlay-dark .overlay-main {
  opacity: 0.9;
}

.overlay-wraper > .container,
.overlay-wraper > .container-fluid,
.overlay-wraper > .wt-icon-box-wraper,
.overlay-wraper > .wt-left-part,
.overlay-wraper > .wt-right-part {
  position: relative;
  z-index: 1;
}

/*left right part css*/
.wt-left-part,
.wt-right-part {
  width: 555px;
}

.wt-left-part {
  float: right;
  padding-right: 15px;
}
.wt-right-part {
  padding-left: 15px;
}

@media only screen and (max-width: 1200px) {
  .wt-left-part,
  .wt-right-part {
    width: 100%;
  }
  .wt-left-part {
    clear: both;
    float: none;
    padding-left: 30px;
  }
  .wt-right-part {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .wt-left-part {
    padding-left: 0px;
    padding-right: 0px;
  }
  .wt-right-part {
    padding-right: 0px;
  }
}
/*use for box*/
.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.overlay-icon {
  list-style: none;
  width: 160px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px -75px;
  z-index: 1 !important;
}
.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
  border: 1px solid #fff;
  color: #fff;
}
.overlay-icon .wt-icon-box-xs {
  line-height: 40px;
}
.overlay-icon a:hover {
  color: #fff;
  background-color: #000;
  border: 1px solid transparent;
}

.overlay-bx:hover a > i,
.wt-thum-bx:hover .overlay-bx a > i,
.wt-box:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.overlay-bx:hover,
.wt-thum-bx:hover .overlay-bx,
.wt-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

/*======================================
9. BOXES CSS 
======================================*/
.rounded-bx,
.wt-box,
.wt-icon-box,
.wt-icon-box-small,
.wt-thum-bx,
.wt-post-thum {
  position: relative;
}

/*-------------------------------------
	1. box content
-------------------------------------*/
.wt-box {
  position: relative;
}
.wt-box.no-margin {
  margin-bottom: 0;
}

/*-------------------------------------
	2. For icons with box
-------------------------------------*/
.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
  display: inline-block;
  text-align: center;
}
.wt-icon-box-xld {
  width: 180px;
  height: 180px;
}
.wt-icon-box-xl {
  width: 150px;
  height: 150px;
}

.wt-icon-box-lg {
  width: 120px;
  height: 120px;
}
.wt-icon-box-md {
  width: 100px;
  height: 100px;
}

.wt-icon-box-sm {
  width: 80px;
  height: 80px;
}

.wt-icon-box-xs {
  width: 40px;
  height: 40px;
}

.wt-icon-box-xld.radius,
.wt-icon-box-xl.radius,
.wt-icon-box-lg.radius,
.wt-icon-box-md.radius,
.wt-icon-box-sm.radius,
.wt-icon-box-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.wt-icon-box-xld i,
.wt-icon-box-xl i,
.wt-icon-box-lg i,
.wt-icon-box-md i,
.wt-icon-box-sm i,
.wt-icon-box-xs i {
  vertical-align: middle;
}
.wt-icon-box-xld i {
  font-size: 100px;
}
.wt-icon-box-xl i {
  font-size: 80px;
}
.wt-icon-box-lg i {
  font-size: 60px;
}
.wt-icon-box-md i {
  font-size: 45px;
}
.wt-icon-box-sm i {
  font-size: 30px;
}
.wt-icon-box-xs i {
  font-size: 20px;
}

.wt-icon-box-xld img,
.wt-icon-box-xl img,
.wt-icon-box-lg img,
.wt-icon-box-md img,
.wt-icon-box-sm img,
.wt-icon-box-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.wt-icon-box-xld img {
  height: 90px;
}
.wt-icon-box-xl img {
  height: 80px;
}
.wt-icon-box-lg img {
  height: 55px;
}
.wt-icon-box-md img {
  height: 40px;
}
.wt-icon-box-sm img {
  height: 30px;
}
.wt-icon-box-xs img {
  height: 20px;
}

/*-------------------------------------
	3. Border
-------------------------------------*/
.bdr-white {
  border-color: #ffffff;
}
.bdr-black {
  border-color: #000000;
}
.bdr-primary {
  border-color: #ffbc13;
}
.bdr-gray {
  border-color: #dddddd;
}
.bdr-gray-light {
  border-color: #eeeeee;
}
.bdr-gray-dark {
  border-color: #333333;
}

.bdr-1,
.bdr-2,
.bdr-3,
.bdr-4,
.bdr-5 {
  border-style: solid;
}
.bdr-dot-1,
.bdr-dot-2,
.bdr-dot-3,
.bdr-dot-4,
.bdr-dot-5 {
  border-style: dotted;
}
.bdr-dash-1,
.bdr-dash-2,
.bdr-dash-3,
.bdr-dash-4,
.bdr-dash-5 {
  border-style: dashed;
}
.bdr-1 {
  border-width: 1px;
}
.bdr-2 {
  border-width: 2px;
}
.bdr-3 {
  border-width: 3px;
}
.bdr-4 {
  border-width: 4px;
}
.bdr-5 {
  border-width: 5px;
}

/*-------------------------------------
	4. For only icons
-------------------------------------*/
.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}
.icon-xld i,
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
  vertical-align: middle;
}
.icon-xld {
  width: 120px;
}
.icon-xld i {
  font-size: 100px;
}
.icon-xl {
  width: 100px;
}
.icon-xl i {
  font-size: 80px;
}
.icon-lg {
  width: 80px;
}
.icon-lg i {
  font-size: 60px;
}
.icon-md {
  width: 60px;
}
.icon-md i {
  font-size: 45px;
}
.icon-sm {
  width: 40px;
}
.icon-sm i {
  font-size: 30px;
}
.icon-xs {
  width: 30px;
}
.icon-xs i {
  font-size: 20px;
}

.icon-xld img,
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.icon-xld img {
  height: 90px;
}
.icon-xl img {
  height: 80px;
}
.icon-lg img {
  height: 70px;
}
.icon-md img {
  height: 50px;
}
.icon-sm img {
  height: 30px;
}
.icon-xs img {
  height: 20px;
}

/*-------------------------------------
	5. place icon with box
-------------------------------------*/
.about-types .wt-icon-box-wraper {
  border-width: 0px 0px 0px 3px;
}

.wt-icon-box-wraper {
  position: relative;
}
.wt-icon-box-wraper .wt-tilte {
  margin-top: 0;
}
.wt-icon-box-wraper .after-titile-line {
  margin-bottom: 10px;
}
.wt-icon-box-wraper p:last-child {
  margin: 0px;
}
.icon-content {
  overflow: hidden;
}
.icon-content p {
  word-break: break-all;
}

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
  display: table;
}
.wt-icon-box-xld .icon-cell,
.wt-icon-box-xl .icon-cell,
.wt-icon-box-lg .icon-cell,
.wt-icon-box-md .icon-cell,
.wt-icon-box-sm .icon-cell,
.wt-icon-box-xs .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

/*-------------------------------------
	6. icon box left align
-------------------------------------*/
.wt-icon-box-wraper.left .wt-icon-box-xld,
.wt-icon-box-wraper.left .wt-icon-box-xl,
.wt-icon-box-wraper.left .wt-icon-box-lg,
.wt-icon-box-wraper.left .wt-icon-box-md,
.wt-icon-box-wraper.left .wt-icon-box-sm,
.wt-icon-box-wraper.left .wt-icon-box-xs {
  float: left;
  margin-right: 20px;
}
.wt-icon-box-wraper.left .icon-xld,
.wt-icon-box-wraper.left .icon-xl,
.wt-icon-box-wraper.left .icon-lg,
.wt-icon-box-wraper.left .icon-md,
.wt-icon-box-wraper.left .icon-sm,
.wt-icon-box-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
}

/*-------------------------------------
	7. icon box right align
-------------------------------------*/
.wt-icon-box-wraper.right {
  text-align: right;
}
.wt-icon-box-wraper.right .wt-icon-box-xld,
.wt-icon-box-wraper.right .wt-icon-box-xl,
.wt-icon-box-wraper.right .wt-icon-box-lg,
.wt-icon-box-wraper.right .wt-icon-box-md,
.wt-icon-box-wraper.right .wt-icon-box-sm,
.wt-icon-box-wraper.right .wt-icon-box-xs {
  float: right;
  display: table;
  margin-left: 20px;
}
.wt-icon-box-wraper.right .icon-xld,
.wt-icon-box-wraper.right .icon-xl,
.wt-icon-box-wraper.right .icon-lg,
.wt-icon-box-wraper.right .icon-md,
.wt-icon-box-wraper.right .icon-sm,
.wt-icon-box-wraper.right .icon-xs {
  float: right;
  margin-left: 10px;
}

/*-------------------------------------
	8. icon box center align
-------------------------------------*/
.wt-icon-box-wraper.center {
  text-align: center;
}
.wt-icon-box-wraper.center .wt-icon-box-xld,
.wt-icon-box-wraper.center .wt-icon-box-xl,
.wt-icon-box-wraper.center .wt-icon-box-lg,
.wt-icon-box-wraper.center .wt-icon-box-md,
.wt-icon-box-wraper.center .wt-icon-box-sm,
.wt-icon-box-wraper.center .wt-icon-box-xs {
  margin-left: auto;
  margin-right: auto;
}

.wt-icon-box-wraper.bx-style-1,
.wt-icon-box-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
}

.wt-icon-box-wraper.bx-style-2.center [class*='wt-icon-box-'],
.wt-icon-box-wraper.bx-style-2.left [class*='wt-icon-box-'],
.wt-icon-box-wraper.bx-style-2.right [class*='wt-icon-box-'] {
  position: absolute;
}

.wt-icon-box-wraper.bx-style-2.center [class*='wt-icon-box-'] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xl {
  margin-left: -75px;
}
.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-lg {
  margin-left: -60px;
}
.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-md {
  margin-left: -50px;
}
.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-sm {
  margin-left: -40px;
}
.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xs {
  margin-left: -20px;
}

.wt-icon-box-wraper.bx-style-2.left [class*='wt-icon-box-'] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.wt-icon-box-wraper.bx-style-2.right [class*='wt-icon-box-'] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

/*-------------------------------------
	9. media with content box css
-------------------------------------*/
.wt-box,
.wt-info,
.wt-tilte,
.wt-tilte-inner {
  position: relative;
}
.wt-tilte-inner {
  display: inline-block;
}
.wt-tilte-inner.skew-title:after {
  content: '';
  position: absolute;
  right: -15px;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: -1;
  -moz-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  transform: skewX(20deg);
}
.wt-box[class*='border-'],
.wt-info[class*='border-'],
.wt-icon-box-wraper[class*='border-'] {
  border-color: #eee;
}

.wt-info.border-1,
.wt-info.border-2,
.wt-info.border-3,
.wt-info.border-4,
.wt-info.border-5 {
  border-top: none;
}

/*-------------------------------------
	10. for images
-------------------------------------*/
.wt-thum-bx,
.wt-post-thum {
  background-color: #000;
}
.wt-thum-bx img,
.wt-post-thum img {
  width: 100%;
  height: auto;
}

/*======================================
10. IMAGES OVERLAY EFFECTS (overly animation on images)  
======================================*/
.wt-img-overlay1:after,
.wt-img-overlay1:before,
.gradi-black:before,
.gradi-black:after,
.gradi-white:before,
.gradi-white:after {
  content: '';
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

/*-------------------------------------
	1. effect 1
-------------------------------------*/
.wt-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.wt-img-overlay1:hover:before,
.wt-box:hover .wt-img-overlay1:before {
  opacity: 0.5;
}

/*-------------------------------------
	2. Gradient-black
-------------------------------------*/
.gradi-black:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 90%,
    rgba(0, 0, 0, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 90%,
    rgba(0, 0, 0, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 90%,
    rgba(0, 0, 0, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}
/*-------------------------------------
	3. Gradient-white
-------------------------------------*/
.gradi-white:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.9) 90%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.9) 90%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.9) 90%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

/*======================================
11. shortcodeS 
======================================*/

/*-------------------------------------
	1. Buttons 
--------------------------------------- */

/* 1.1 theme default button */
.btn {
  padding-top: 9px;
  padding-bottom: 9px;
}

.site-button,
.site-button-secondry {
  padding: 10px 20px;
  display: inline-block;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  outline: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  line-height: 1.42857;
  margin-left: -1px;
  text-decoration: none !important;
}
.site-button {
  color: #fff;
}
.site-button-secondry {
  color: #000;
}

.site-button {
  background-color: #000;
}
.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button {
  background-color: #000;
  color: #fff;
}
.site-button:hover {
  background-color: #efefef;
  color: #000;
}
.site-button-secondry {
  background-color: #efefef;
}

.site-button-secondry:active,
.site-button-secondry:hover,
.site-button-secondry:focus,
.active > .site-button-secondry {
  background-color: #000;
  color: #fff;
}

.video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #fff;
  text-align: center;
  margin: -25px;
  color: #fff;
  display: block;
  z-index: 10;
  font-size: 14px;
}
.video-play-btn:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid transparent;
}
.video-play-btn i {
  margin-right: -5px;
}

/* 1.2 button text uppercase */
.site-button.text-uppercase {
  text-transform: uppercase;
}

/* 1.3 button size */
.button-sm {
  padding: 5px 10px;
  font-size: 12px;
}
.button-lg {
  padding: 20px 35px;
  font-size: 16px;
}
.button-xl {
  padding: 30px 50px;
  font-size: 24px;
}

/* 1.4 button rounded */
.radius-no {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.radius-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.radius-xl {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.radius-bx {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}

/* 1.6 white */
.site-button.white {
  background-color: #fff;
  color: #777;
}
.site-button.white:hover,
.site-button.white:active,
.site-button.white:focus {
  background-color: #f4f4f4;
  color: #555;
}

/* 1.7 black */
.site-button.black {
  background-color: #000;
  color: #fff;
}
.site-button.black:hover,
.site-button.black:active,
.site-button.black:focus {
  background-color: #171717;
  color: #fff;
}

.site-button.outline.black.button-app:focus {
  background-color: transparent !important;
  color: #000 !important;
  border: 2px solid #171717;
}

/* 1.8 Gray */
.site-button.gray {
  background-color: #666666;
  color: #fff;
}
.site-button.gray:hover,
.site-button.gray:active,
.site-button.gray:focus {
  background-color: #555555;
  color: #fff;
}

/* 1.9 pink */
.site-button.pink {
  background-color: #e63f75;
  color: #fff;
}
.site-button.pink:hover,
.site-button.pink:active,
.site-button.pink:focus {
  background-color: #d22b61;
  color: #fff;
}

/* 1.10 Blue */
.site-button.blue {
  background-color: #42b8d4;
  color: #fff;
}
.site-button.blue:hover,
.site-button.blue:active,
.site-button.blue:focus {
  background-color: #2ca2be;
  color: #fff;
}

/* 1.11 Green */
.site-button.green {
  background-color: #35b494;
  color: #fff;
}
.site-button.green:hover,
.site-button.green:active,
.site-button.green:focus {
  background-color: #26a585;
  color: #fff;
}

/* 1.12 Orange */
.site-button.orange {
  background-color: #e56713;
  color: #fff;
}
.site-button.orange:hover,
.site-button.orange:active,
.site-button.orange:focus {
  background-color: #d55703;
  color: #fff;
}

/* 1.13 Red */
.site-button.red {
  background-color: #d93223;
  color: #fff;
}
.site-button.red:hover,
.site-button.red:active,
.site-button.red:focus {
  background-color: #c51e0f;
  color: #fff;
}

/*1.14 Brown */
.site-button.brown {
  background-color: #69441f;
  color: #fff;
}
.site-button.brown:hover,
.site-button.brown:active,
.site-button.brown:focus {
  background-color: #5f3a15;
  color: #fff;
}

/* 1.15 Yellow */
.site-button.yellow {
  background-color: #ecc731;
  color: #fff;
}
.site-button.yellow:hover,
.site-button.yellow:active,
.site-button.yellow:focus {
  background-color: #d4af19;
  color: #fff;
}

/* 1.16 purple */
.site-button.purple {
  background-color: #ae1af7;
  color: #fff;
}
.site-button.purple:hover,
.site-button.purple:active,
.site-button.purple:focus {
  background-color: #9804e1;
  color: #fff;
}

/* 1.17 Graphical */
.site-button.graphical {
  color: #fff;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3),
    0 1px 3px -1px rgba(45, 60, 72, 0.5);
  -webkit-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3),
    0 1px 3px -1px rgba(45, 60, 72, 0.5);
  -moz-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3),
    0 1px 3px -1px rgba(45, 60, 72, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.site-button.graphical:active {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset;
}

/* 1.19 Outline */
.site-button.outline {
  color: #888;
  background: none;
  border-width: 2px;
  border-style: solid;
  border-color: #eee;
}

/* 1.20 Outline white  */
.site-button.outline.white {
  color: #e7e7e7;
  border-color: #e7e7e7;
}
.site-button.outline.white:hover {
  color: #666666;
}

/* 1.21 Outline black */
.site-button.outline.black {
  color: #171717;
  border-color: #171717;
}

/* 1.22 Outline gray */
.site-button.outline.gray {
  color: #666666;
  border-color: #666666;
}

/* 1.23 Outline pink */
.site-button.outline.pink {
  color: #e63f75;
  border-color: #e63f75;
}

/* 1.24 Outline blue */
.site-button.outline.blue {
  color: #42b8d4;
  border-color: #42b8d4;
}

/* 1.25 Outline green */
.site-button.outline.green {
  color: #35b494;
  border-color: #35b494;
}

/* 1.26 Outline orange */
.site-button.outline.orange {
  color: #e56713;
  border-color: #e56713;
}

/* 1.27 Outline red */
.site-button.outline.red {
  color: #d93223;
  border-color: #d93223;
}

/* 1.28 Outline brown */
.site-button.outline.brown {
  color: #69441f;
  border-color: #69441f;
}

/* 1.29 Outline yellow */
.site-button.outline.yellow {
  color: #ffbc13;
  border-color: #ffbc13;
}

/* 1.30 Outline purple */
.site-button.outline.purple {
  color: #ae1af7;
  border-color: #ae1af7;
}

/* 1.31 Outline hover */
.site-button.outline:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}

/* 1.32 button text link */
.site-button-link {
  display: inline-block;
  font-weight: 600;
}

/* 1.33 text link white */
.site-button-link.white {
  color: #e7e7e7;
}
.site-button-link.white:hover,
.site-button-link.white:active,
.site-button-link.white:focus {
  color: #ccc;
}

/* 1.34 text link black */
.site-button-link.black {
  color: #171717;
}
.site-button-link.black:hover,
.site-button-link.black:active,
.site-button-link.black:focus {
  color: #000;
}

/* 1.35 text link Gray */
.site-button-link.gray {
  color: #666666;
}
.site-button-link.gray:hover,
.site-button-link.gray:active,
.site-button-link.gray:focus {
  color: #555555;
}

/* 1.36 text link pink */
.site-button-link.pink {
  color: #e63f75;
}
.site-button-link.pink:hover,
.site-button-link.pink:active,
.site-button-link.pink:focus {
  color: #2ca2be;
}

/* 1.37 text link Blue */
.site-button-link.blue {
  color: #42b8d4;
}
.site-button-link.blue:hover,
.site-button-link.blue:active,
.site-button-link.blue:focus {
  color: #2ca2be;
}

/* 1.38 text link Green */
.site-button-link.green {
  color: #35b494;
}
.site-button-link.green:hover,
.site-button-link.green:active,
.site-button-link.green:focus {
  color: #26a585;
}

/* 1.39 text link Orange */
.site-button-link.orange {
  color: #e56713;
}
.site-button-link.orange:hover,
.site-button-link.orange:active,
.site-button-link.orange:focus {
  color: #d55703;
}

/* 1.40 text link Red */
.site-button-link.red {
  color: #d93223;
}
.site-button-link.red:hover,
.site-button-link.red:active,
.site-button-link.red:focus {
  color: #c51e0f;
}

/* 1.41 text link Brown */
.site-button-link.brown {
  color: #69441f;
}
.site-button-link.brown:hover,
.site-button-link.brown:active,
.site-button-link.brown:focus {
  color: #5f3a15;
}

/* 1.42 text link Yellow */
.site-button-link.yellow {
  color: #ecc731;
}
.site-button-link.yellow:hover,
.site-button-link.yellow:active,
.site-button-link.yellow:focus {
  color: #d4af19;
}

/* 1.43 text link purple */
.site-button-link.purple {
  color: #ae1af7;
}
.site-button-link.purple:hover,
.site-button-link.purple:active,
.site-button-link.purple:focus {
  color: #9804e1;
}

/* 1.44 app strore */
.site-button.button-app {
  text-align: left;
  padding: 15px 25px;
}
.site-button.button-app i {
  font-size: 45px;
  float: left;
  margin-right: 15px;
}
.site-button.button-app strong {
  display: block;
  font-size: 16px;
}

/* 1.45 filter buttons css */
.masonry-filter {
  display: table;
  margin-bottom: 0;
}
.masonry-filter > li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
}

.masonry-filter > li a {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.masonry-filter > li.active a,
.masonry-filter > li a:hover,
.masonry-filter > li a:active,
.masonry-filter > li a:focus {
  color: #a0a0a0;
}

@media only screen and (max-width: 600px) {
  .masonry-filter > li {
    margin-bottom: 10px;
  }
  .masonry-filter > li a {
    font-size: 14px !important;
  }
}
/* 1.45(1) Masonry filter alignment [Left-Right-Center] */
.filter-wrap.right > .masonry-filter {
  float: right;
}
@media only screen and (max-width: 991px) {
  .filter-wrap.right > .masonry-filter {
    float: none;
  }
}
.filter-wrap.center > .masonry-filter {
  margin-left: auto;
  margin-right: auto;
}
/* 1.45(2) Masonry filter link style */
.masonry-filter.link-style > li a {
  font-size: 15px;
}
.masonry-filter.link-style > li:after {
  content: '/';
  position: relative;
  right: -18px;
  opacity: 0.3;
}
.masonry-filter.link-style.white > li:after {
  color: #fff;
}
.masonry-filter.link-style > li:last-child:after {
  display: none;
}
.masonry-filter.link-style.white > li.active a,
.masonry-filter.link-style.white > li a:hover,
.masonry-filter.link-style.white > li a:active,
.masonry-filter.link-style.white > li a:focus {
  color: #f7c20a;
}
.masonry-filter.has-bg > li a {
  color: #fff;
}
.masonry-filter.link-style.has-bg > li.active a,
.masonry-filter.link-style.has-bg > li:hover a,
.masonry-filter.link-style.has-bg > li:active a,
.masonry-filter.link-style.has-bg > li:focus a {
  color: #ffbc13;
}

/* 1.45(3) Masonry filter button style */
.masonry-filter.button-style > li {
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .masonry-filter.button-style > li {
    margin-bottom: 10px;
  }
}
.masonry-filter.button-style > li a {
  background-color: #666666;
  color: #fff;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}
.masonry-filter.button-style > li.active a,
.masonry-filter.button-style > li a:hover,
.masonry-filter.button-style > li a:active,
.masonry-filter.button-style > li a:focus {
  background-color: #000;
  color: #fff;
}
.masonry-filter.button-style.has-bg > li a {
  background-color: #fff;
  color: #666;
}
.masonry-filter.button-style.has-bg > li.active a,
.masonry-filter.button-style.has-bg > li a:hover,
.masonry-filter.button-style.has-bg > li a:active,
.masonry-filter.button-style.has-bg > li a:focus {
  background-color: #000;
  color: #fff;
}

/* 1.45(4) Masonry filter button outline style */
.masonry-filter.outline-style > li {
  margin-right: 10px;
}
.masonry-filter.outline-style > li a {
  border-width: 2px;
  border-style: solid;
  border-color: #666666;
  color: #666666;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}
.masonry-filter.outline-style.rounded-corner > li a {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.masonry-filter.outline-style > li.active a,
.masonry-filter.outline-style > li a:hover,
.masonry-filter.outline-style > li a:active,
.masonry-filter.outline-style > li a:focus {
  border-color: #000;
  color: #000;
}
.masonry-filter.outline-style.has-bg > li a {
  border-color: #fff;
  color: #fff;
}
.masonry-filter.outline-style.has-bg > li.active a,
.masonry-filter.outline-style.has-bg > li a:hover,
.masonry-filter.outline-style.has-bg > li a:active,
.masonry-filter.outline-style.has-bg > li a:focus {
  border-color: #000;
  color: #000;
}

@media only screen and (max-width: 767px) {
  .masonry-item {
    width: 100%;
  }
}

/* 1.45(5) half effect button outline style */
.btn-half {
  cursor: pointer;
  background-color: #000;
  z-index: 0;
  display: inline-block;
  position: relative;
}

.btn-half span {
  color: #fff;
  display: block;
  padding-left: 0%;
  padding-right: 25px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 5px;
  transform: scaleX(0.9);
  transform-origin: center left;
  transition: color 0.3s ease;
  position: relative;
  z-index: 1;
}

.btn-half em {
  position: absolute;
  height: 1px;
  background: #fff;
  width: 22%;
  right: 23px;
  top: 50%;
  transform: scaleX(0.25);
  transform-origin: center right;
  transition: all 0.3s ease;
  z-index: 1;
}

.btn-half:before,
.btn-half:after {
  content: '';
  background-color: rgba(255, 255, 255, 0.2);
  height: 50%;
  width: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.btn-half:before {
  top: 0;
  left: 0;
  right: auto;
}
.btn-half:after {
  bottom: 0;
  right: 0;
  left: auto;
}

.btn-half.site-button:hover {
  background-color: #000;
}
.btn-half:hover:before {
  width: 100%;
  right: 0;
  left: auto;
}
.btn-half:hover:after {
  width: 100%;
  left: 0;
  right: auto;
}

.btn-half:hover span {
  color: #fff;
}
.btn-half:hover em {
  background: #fff;
  transform: scaleX(1);
}
/*-------------------------------------
	2. Title separators 
-------------------------------------*/
.wt-separator-outer {
  overflow: hidden;
}
/*Separator defualt*/
.wt-separator {
  display: inline-block;
  height: 3px;
  width: 50px;
  position: relative;
  background-color: #000;
}
.wt-separator .separator-left,
.wt-separator .separator-right {
  position: absolute;
  top: 50%;
  width: 70px;
  height: 2px;
  margin-top: -1px;
}
.wt-separator .separator-left {
  left: -80px;
}
.wt-separator .separator-right {
  right: -80px;
}
/*Separator liner*/
.wt-separator.style-liner {
  width: 20px;
  height: 4px;
}
/*Separator square*/
.wt-separator.style-square {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-width: 3px;
  border-style: solid;
  border-color: #2d3239;
}
.wt-separator.style-square .separator-left,
.wt-separator.style-square .separator-right {
  height: 3px;
}
.wt-separator.style-square.has-bg {
  border-color: #fff;
}

/*Separator icon*/
.wt-separator.style-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
}

/*-------------------------------------
	3. Deviders 
-------------------------------------*/
.wt-divider {
  height: 1px;
  position: relative;
  margin: 30px 0;
}
.wt-divider.divider-2px {
  height: 2px;
}
.wt-divider.divider-3px {
  height: 3px;
}
.wt-divider.divider-4px {
  height: 4px;
}
.wt-divider i {
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wt-divider.icon-left {
  margin-left: 40px;
}
.wt-divider.icon-left i {
  left: -40px;
}
.wt-divider.icon-right {
  margin-right: 40px;
}
.wt-divider.icon-right i {
  left: auto;
  right: -40px;
}
.wt-divider.icon-center i {
  left: 50%;
  margin-left: -5px;
}

/*-------------------------------------
	4. TABS 
-------------------------------------*/
.wt-tabs:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}
@media only screen and (max-width: 767px) {
  .wt-tabs .nav-tabs > li {
    float: none;
  }
}
.wt-tabs p:last-child {
  margin-bottom: 0;
}
/*4.1 tabs style 1 [ default ] */
.wt-tabs .tab-pane {
  padding: 20px 0;
}
.wt-tabs .nav-tabs > li > a {
  color: #000;
  font-size: 12px;
  padding: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 0px;
}
.wt-tabs .nav-tabs > li.active > a,
.wt-tabs .nav-tabs > li.active > a:focus,
.wt-tabs .nav-tabs > li.active > a:hover {
  background-color: #fff;
  border-bottom: 1px solid #000;
  color: #000;
}
.wt-tabs .nav > li > a:focus,
.wt-tabs .nav > li > a:hover {
  background-color: transparent;
  border: 0px;
}
.wt-tabs .nav li a:hover {
  border: 0px;
}

.wt-tabs .nav-tabs > li > a i {
  margin-right: 5px;
}
.wt-tabs.tabs-default.has-bg .nav-tabs > li > a {
  color: #fff;
}
.wt-tabs.tabs-default.has-bg .nav-tabs > li.active > a,
.wt-tabs.tabs-default.has-bg .nav-tabs > li.active > a:focus,
.wt-tabs.tabs-default.has-bg .nav-tabs > li.active > a:hover {
  color: #000;
  border-color: #ddd #ddd #fff;
}
.wt-tabs.tabs-default.has-bg .tab-pane {
  color: #fff;
}
/* tabs nav center */
.wt-tabs.nav-center > .nav-tabs {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

/*4.2 tabs bg  [ tabs background ] */
.wt-tabs.bg-tabs .nav-tabs > li > a {
  background-color: #f0f0f0;
  border: 0px;
  margin-right: -1px;
}
.wt-tabs.bg-tabs .nav-tabs > li.active > a {
  border-bottom: 0px;
  background-color: #000;
  color: #fff;
}
.wt-tabs.bg-tabs.has-bg .tab-pane {
  color: #fff;
}

/*4.3 tabs bg  [ tabs background ] */
.wt-tabs.vertical.bg-tabs .nav-tabs > li > a {
  border: 1px solid transparent;
}
.wt-tabs.vertical.bg-tabs .nav-tabs > li.active > a {
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  background-color: #000;
  color: #fff;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs > li > a {
  border: 1px solid #ddd;
}
.wt-tabs.vertical.right.bg-tabs .nav-tabs > li.active > a {
  border-left: 1px solid transparent;
}

/*4.4 tabs bg & top border  [ tabs background & top border in active ] */
.wt-tabs.border-top .nav-tabs > li.active > a {
  color: #1a1a1a;
}
.wt-tabs.border-top .nav-tabs > li.active > a:after {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
}
.wt-tabs.border-top.vertical .nav-tabs > li.active > a:after {
  top: 0px;
  left: -1px;
  width: 3px;
  height: 100%;
}

/*4.4 tabs style 2 [ content with border outer ] */
.wt-tabs.border .tab-pane {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: -1px;
}

/*4.5 tabs style 3  [ left-nav ] */
.wt-tabs.vertical .nav-tabs {
  float: left;
  width: 170px;
  border-bottom: none;
  border-right: 0px;
}

@media only screen and (max-width: 720px) {
  .wt-tabs.vertical .nav-tabs {
    width: 100%;
    float: none;
    border-right: 0px;
  }
  .wt-tabs.vertical .tab-content {
    width: 100%;
    margin-left: 0px !important;
  }
}

.wt-tabs.vertical .tab-pane {
  padding: 10px 0 10px 20px;
}
.wt-tabs.vertical .nav-tabs li {
  float: none;
  margin-right: 0px;
}
.wt-tabs.vertical .nav-tabs li a {
  margin-right: 0;
  border-right: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0;
  border-radius: 0px;
  color: #000;
  border-bottom: 1px solid transparent;
}

.wt-tabs.vertical .nav-tabs li.active a {
  border-bottom: 1px solid #000;
  background-color: #fff;
}
.wt-tabs.vertical .tab-content {
  border-left: 0px;
  margin-left: 169px;
}

/*4.6 tabs style 4  [ left-nav & content with border ] */
.wt-tabs.vertical.border .tab-pane {
  padding: 20px;
  margin-left: -1px;
}

/*4.7 tabs style 5  [ right-nav ] */
.wt-tabs.vertical.right .nav-tabs {
  border-left: 1px solid #ddd;
  border-right: none;
  float: right;
}
.wt-tabs.vertical.right .nav-tabs li {
  margin-right: 0;
  margin-left: -1px;
}
.wt-tabs.vertical.right .nav-tabs li a {
  border-right: 1px solid transparent;
  border-left: none;
}
.wt-tabs.vertical.right .nav-tabs li.active a {
  border-right: 1px solid #ddd;
  border-left: none;
}
.wt-tabs.vertical.right .tab-content {
  border-left: none;
  border-right: 1px solid #ddd;
  margin-right: 169px;
  margin-left: 0;
}
.wt-tabs.vertical.right .tab-pane {
  padding: 10px 20px 10px 0;
}

/*4.8 tabs style 6  [ right-nav & content with border ]*/
.wt-tabs.vertical.right.border .tab-pane {
  padding: 20px;
  margin-right: -1px;
}

/*-------------------------------------
	5. Accordians 
-------------------------------------*/
.wt-accordion .wt-panel {
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  border-radius: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.acod-head {
  position: relative;
}
.acod-title {
  margin-top: 0;
  margin-bottom: 0;
}
.acod-title a {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.acod-head .fa {
  margin-right: 5px;
}

.acod-head a {
  display: block;
  padding: 15px 40px 15px 15px;
  letter-spacing: 1px;
  font-size: 15px;
}
.acod-head a,
.acod-head a:hover,
.acod-head a:active,
.acod-head a:focus {
  color: #000;
}
.acod-body {
  color: #000;
  line-height: 24px;
}
.wt-accordion.acc-bg-gray .acod-body {
  padding-left: 10px;
  padding-right: 10px;
}

/*Accodian open close indicator css*/
.acod-head .indicator {
  padding: 15px;
  color: #ababab;
  position: absolute;
  right: 0;
  top: 0;
}
.acod-head .indicator .fa {
  position: relative;
}
.acod-head .indicator .fa:after,
.acod-head .indicator .fa:before {
  position: absolute;
  content: '';
  right: 0px;
  top: -4px;
  height: 1px;
  width: 13px;
  background-color: #000;
}

.acod-head .indicator .fa:after {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.acc-bg-dark .acod-head .indicator .fa:after,
.acc-bg-dark .acod-head .indicator .fa:before {
  background-color: #fff;
}

.acod-head .indicator .fa,
.acod-head.acc-actives .indicator .fa {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.acod-head.acc-actives .indicator .fa,
.acod-head [aria-expanded='true'] .indicator .fa {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 4px;
}

/* 1. Accordion defult */
.acc-default .acod-title a {
  padding: 5px 0;
}
.acc-default .acod-head .indicator {
  padding: 0;
}
.acc-default.acc-has-bg .acod-head a,
.acc-default.acc-has-bg .acod-head a:hover,
.acc-default.acc-has-bg .acod-head a:active,
.acc-default.acc-has-bg .acod-head a:focus,
.acc-default.acc-has-bg .acod-head .indicator,
.acc-default.acc-has-bg .acod-body {
  color: #fff;
}

/* 2. Accordion outline */
.acc-outline .acod-title a {
  border: 1px solid #ddd;
}
.acc-outline.acc-has-bg .acod-head a,
.acc-outline.acc-has-bg .acod-head a:hover,
.acc-outline.acc-has-bg .acod-head a:active,
.acc-outline.acc-has-bg .acod-head a:focus,
.acc-outline.acc-has-bg .acod-head .indicator,
.acc-outline.acc-has-bg .acod-body {
  color: #fff;
}

/* 3. Accordion bg gray */
.acc-bg-gray a {
  background-color: #f5f5f5;
}
.acc-bg-gray .acod-head .indicator {
  color: #777;
}
.acc-bg-gray.acc-has-bg .acod-body {
  color: #fff;
}
/* 4. Accordion bg primary */
.acc-bg-primary a {
  background-color: #000;
}
.acc-bg-primary .acod-head a,
.acc-bg-primary .acod-head a:hover,
.acc-bg-primary .acod-head a:active,
.acc-bg-primary .acod-head a:focus {
  color: #fff;
}
.acc-bg-primary .acod-head .indicator {
  color: #fff;
}
.acc-bg-primary.acc-has-bg .acod-body {
  color: #fff;
}
/* 5. Accordion bg dark */
.acc-bg-dark a {
  background-color: #000;
}
.acc-bg-dark .acod-head a,
.acc-bg-dark .acod-head a:hover,
.acc-bg-dark .acod-head a:active,
.acc-bg-dark .acod-head a:focus {
  color: #fff;
}
.acc-bg-dark .acod-head .indicator {
  color: #fff;
}
.acc-bg-dark.acc-has-bg .acod-body {
  color: #fff;
}

/*-------------------------------------
	6. Carousels
-------------------------------------*/
/* 6.1 Client carousel 1*/
.ow-client-logo {
  display: table;
  width: 100%;
}
.client-logo {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}
.owl-carousel .ow-client-logo img {
  max-height: 100%;
  width: auto;
  display: inline-block;
}

.client-logo-media img {
  max-width: 100% !important;
  margin: 0px auto !important;
  width: auto !important;
}

/*-------------------------------------
	7. Testimonials 
-------------------------------------*/
.testimonial-pic {
  background: #c3c3c3;
  width: 100px;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #fff;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}
.testimonial-pic.radius img {
  width: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}
.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}
.quote-left,
.quote-right {
  position: relative;
}

.quote-left:before,
.quote-right:after {
  font-family: 'FontAwesome';
  position: absolute;
  z-index: 10;
  top: 15px;
}

.quote-left:before {
  content: '\f10d';
  left: 0;
}
.quote-right:after {
  content: '\f10e';
  left: auto;
  right: 0;
}
.testimonial-text {
  padding: 15px;
  position: relative;
}
.testimonial-text p:last-child {
  margin: 0;
}
.testimonial-detail {
  padding: 5px;
}

.testimonial-name,
.testimonial-position {
  display: block;
}
.testimonial-position {
  font-style: italic;
}
.testimonial-text p {
  margin: 0;
}

/*7.1 testimonial with background image*/
.testimonial-bg {
  color: #fff;
}

/*7.2 testimonial 6*/
.testimonial-6,
.testimonial-grid-6 {
  position: relative;
}

.testimonial-6 {
  padding: 4px 4px 4px 4px;
}
.testimonial-6 .testimonial-text,
.testimonial-grid-6 .testimonial-text {
  padding: 30px;
  margin-bottom: 20px;
  -webkit-box-shadow: 3px 3px 5px rgb(0, 0, 0, 0.1);
  box-shadow: 3px 3px 5px 0 rgb(0, 0, 0, 0.1);
}
.testimonial-6 .testimonial-pic,
.testimonial-grid-6 .testimonial-pic {
  width: 80px;
  height: 80px;
  position: relative;
  border: 0px;
}
.testimonial-6 .testimonial-pic:after,
.testimonial-6 .testimonial-pic:before,
.testimonial-grid-6 .testimonial-pic:after,
.testimonial-grid-6 .testimonial-pic:before {
  content: '';
  position: absolute;
  background-color: #000;
}
.testimonial-6 .testimonial-pic:after,
.testimonial-grid-6 .testimonial-pic:after {
  left: -3px;
  top: -3px;
  width: 3px;
  height: 30px;
}
.testimonial-6 .testimonial-pic:before,
.testimonial-grid-6 .testimonial-pic:before {
  left: 0px;
  top: -3px;
  width: 30px;
  height: 3px;
}

.testimonial-6 .testimonial-name,
.testimonial-grid-6 .testimonial-name,
.testimonial-6 .testimonial-position,
.testimonial-grid-6 .testimonial-position {
  display: block;
}
.testimonial-6 .fa-quote-left,
.testimonial-grid-6 .fa-quote-left {
  font-size: 18px;
}

.testimonial-6 .testimonial-paragraph p,
.testimonial-grid-6 .testimonial-paragraph p {
  padding: 0px 0px;
}

.testimonial-6 .testimonial-name,
.testimonial-grid-6 .testimonial-name {
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
}

.testimonial-6 .testimonial-position,
.testimonial-grid-6 .testimonial-position {
  font-style: normal;
}

.testimonial-6 .testimonial-detail,
.testimonial-grid-6 .testimonial-detail {
  padding-left: 0px;
  padding-right: 0px;
}

/* testimonial 6 owl-dots*/
.testimonial-home.owl-carousel .owl-dots .owl-dot span {
  height: 10px;
  width: 20px;
  background: no-repeat;
  border: 1px solid #000;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.testimonial-home.owl-carousel .owl-dots .owl-dot.active span {
  background-color: #ffc905 !important;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

/*-------------------------------------
	8. Pricing table
 -------------------------------------*/
.pricingtable-inner {
  text-align: center;
}
.pricingtable-price {
  padding: 10px;
  background-color: #ffbc13;
}
.pricingtable-bx {
  font-size: 70px;
  font-family: Arial;
  color: #666666;
  color: #1a1a1a;
}
.pricingtable-type {
  font-size: 20px;
  text-transform: uppercase;
  font-size: 24px;
  color: #fff;
}
.pricingtable-type:before {
  content: '/';
  margin-right: 3px;
}
.pricingtable-title {
  background-color: #1a1a1a;
  padding: 20px;
  text-transform: uppercase;
}
.pricingtable-title * {
  margin: 0;
  color: #fff;
}
.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #e9e9e9;
}
.pricingtable-features li {
  padding: 12px;
  border-bottom: 1px solid #e9e9e9;
}
.pricingtable-features li i {
  margin: 0 3px;
}
.pricingtable-features li:nth-child(even) {
  background-color: #f4f7f8;
}
.pricingtable-features li:last-child {
  border-bottom: none;
}
.pricingtable-footer {
  margin-top: -1px;
  padding: 20px;
  background-color: #1a1a1a;
}

.pricingtable-highlight {
  margin: -20px 0;
  -moz-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 99;
}
.pricingtable-highlight .pricingtable-price {
  padding: 20px 10px;
}
.pricingtable-highlight .pricingtable-footer {
  padding: 30px 20px;
}
.no-col-gap .pricingtable-wrapper {
  margin-left: -1px;
}
@media only screen and (max-width: 420px) {
  .pricingtable-bx {
    font-size: 40px;
  }
  .pricingtable-type {
    font-size: 18px;
  }
}

/*8.2 pricing table style 4*/

.pricing-table-style-4 .pricingtable-inner {
  position: relative;
  overflow: hidden;
}
.pricing-table-style-4 .pricingtable-inner.radius {
  -webkit-border-radius: 40px 40px 40px 40px;
  border-radius: 40px 40px 40px 40px;
}
.pricing-table-style-4 .pricingtable-bx {
  font-size: 50px;
}
.pricing-table-style-4 .pricingtable-type {
  font-size: 14px;
  font-style: italic;
  color: #fff;
  font-family: 'Crete Round', serif;
  text-transform: none;
}
.pricing-table-style-4 .pricingtable-type:before {
  content: '';
}
.pricing-table-style-4 .pricingtable-inner-overlay {
  position: relative;
  z-index: 1;
}
.pricing-table-style-4 .pricingtable-inner-overlay .overlay-main {
  z-index: -1;
}
.pricing-table-style-4 .pricingtable-title {
  background-color: inherit;
  color: #fff;
  padding: 20px 0px 0px 0px;
}
.pricing-table-style-4 .pricingtable-title * {
  color: #fff;
}
.pricing-table-style-4 .pricingtable-bx {
  color: #fff;
}
.pricing-table-style-4 .pricingtable-price {
  padding: 10px 10px;
}
.pricing-table-style-4 .pricingtable-highlight .pricingtable-price {
  padding: 20px 10px;
}

.pricing-table-style-4 .pricingtable-price {
  background-color: inherit;
}
.pricing-table-style-4 .pricingtable-price span {
  display: block;
}

.pricing-table-style-4 .pricingtable-features li:nth-child(2n) {
  background-color: inherit;
}
.pricing-table-style-4 .pricingtable-footer {
  background-color: inherit;
}
.pricing-table-style-4 .pricingtable-features {
  border: none;
  padding-top: 20px;
}
.pricing-table-style-4 .pricingtable-features li {
  border-bottom: 0px;
}

/*-------------------------------------
	9. Alert box 
-------------------------------------*/
.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px;
}
.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px;
}
.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px;
}
.alert[class*='alert-'] i {
  margin-right: 8px;
}
.alert.no-radius {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.alert.no-bg {
  background-color: transparent;
  border-width: 2px;
}
.alert[class*='alert-'] ul {
  padding-left: 25px;
  margin-top: 10px;
}
.alert[class*='alert-'] ul li:before {
  color: #a94442;
}

/*-------------------------------------
	10. Image effects 
-------------------------------------*/
.wt-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
}
.wt-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
}

/* 10.1 image-opacity */
.wt-img-effect.opacity img:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  -webkit-opacity: 0.8;
}

/*10.2 image-zoom */
.wt-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}
.wt-img-effect.zoom-slow:hover img {
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
}

/*10.3 image-zoom-slow */
.wt-img-effect.zoom:hover img {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

/*10.4 image-shrink */
.wt-img-effect.shrink:hover img {
  transform: scale(0.8);
  -ms-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -o-transform: scale(0.8);
  -moz-transform: scale(0.8);
}

/*10.5 image-side-pan */
.wt-img-effect.side-pan:hover img {
  margin-left: -9%;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
}

/*10.6 image-vertical-pan */
.wt-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
}

/*10.7 image-fade-in */
.wt-img-effect.fade-in {
  background: #000;
}
.wt-img-effect.fade-in img {
  opacity: 0.65;
  -moz-opacity: 0.65;
  -webkit-opacity: 0.65;
}
.wt-img-effect.fade-in:hover img {
  opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
}

/*10.8 image-fade-out */
.wt-img-effect.fade-out {
  background: #000;
}
.wt-img-effect.fade-out:hover img {
  opacity: 0.7;
  -moz-opacity: 0.7;
  -webkit-opacity: 0.7;
}

/*10.9 image-rotate */
.wt-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

/*10.10 image-sepia */
.wt-img-effect.sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}
.algo-image-hover.sepia:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}

/*10.11 image-blurr */
.wt-img-effect.blurr img {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.wt-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

/*10.12 image-blurr-invert */
.wt-img-effect.blurr-invert img {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.wt-img-effect.blurr-invert img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.wt-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}

/*10.13 image off color */
.wt-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}
.wt-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

/*10.14 image on color */
.wt-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.wt-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

/*-------------------------------------
	11. Modal pop 
-------------------------------------*/
.modal-xlg {
  width: 1000px;
}
.provider_registration .bootstrap-select div.dropdown-menu ul li a {
  font-weight: 600;
}
.provider_registration
  .bootstrap-select
  div.dropdown-menu
  ul
  li
  a
  span.childcat {
  font-weight: 400;
  color: #7c7c7c;
}

@media only screen and (max-width: 1024px) {
  .modal-xlg {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
}

/*-------------------------------------
	12. Social icons 
-------------------------------------*/
.social-icons {
  list-style: none;
  margin-left: -3px;
  margin-right: -3px;
  font-size: 12px;
}
.social-icons li {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.social-icons li:last-child {
  margin-right: 0px;
}
.social-icons li a {
  display: block;
  padding: 3px;
}

.social-icons.social-md {
  font-size: 18px;
}
.social-icons.social-lg li a {
  font-size: 24px;
}
.social-icons.has-bg li a:hover {
  color: #fff;
}
.social-icons.social-light a {
  color: #fff;
}
.social-icons.social-light.has-bg li a:hover {
  color: #777;
}
.social-icons.social-dark a {
  color: #777;
}
.social-icons.social-dark.has-bg li a:hover {
  color: #777;
}

.social-icons.social-square a,
.social-icons.social-radius a {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;
}
.social-icons.social-square.social-md a,
.social-icons.social-radius.social-md a {
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 0;
}
.social-icons.social-square.social-lg a,
.social-icons.social-radius.social-lg a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
}

.social-icons.social-square.social-primary a,
.social-icons.social-radius.social-primary a {
  background-color: #000;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.social-icons.social-square.social-primary a:hover,
.social-icons.social-radius.social-primary a:hover {
  background-color: #1a1a1a;
}
.social-icons.social-square.social-primary.has-bg a:hover,
.social-icons.social-radius.social-primary.has-bg a:hover {
  border: 1px solid rgba(255, 255, 255, 1);
}

.social-icons.social-square.social-dark a,
.social-icons.social-radius.social-dark a {
  background-color: #fff;
}

.social-icons.social-square.social-dark a:hover,
.social-icons.social-radius.social-dark a:hover {
  background-color: #1a1a1a;
  color: #fff;
}
.social-icons.social-square.social-dark.has-bg a:hover,
.social-icons.social-radius.social-dark.has-bg a:hover {
  border: 1px solid #777;
}

.social-icons.social-square.social-darkest a,
.social-icons.social-radius.social-darkest a {
  border: 1px solid #4a4c4c;
}
.social-icons.social-darkest a {
  color: #454747;
}

.social-icons.social-square.social-darkest a:hover,
.social-icons.social-radius.social-darkest a:hover {
  background-color: #000;
  color: #fff;
}

.social-icons.social-radius a {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.social-icons.social-square.social-dark.white-border a,
.social-icons.social-radius.social-dark.white-border a {
  background: none;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 1);
}
/*12.1 social icon default */
.wt-social-icon {
  display: inline-block;
  margin: 0 -3px;
  padding: 0;
}
.wt-social-icon li {
  display: inline-block;
  padding: 0;
  font-size: 12px;
}
.wt-social-icon li a {
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 4px;
}
.wt-social-icon li .fa {
  vertical-align: middle;
}

/*12.2 social icon with border */
.wt-social-icon.border li {
  padding: 0 3px;
}
.wt-social-icon.border li a {
  border: 1px solid #efeded;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

/*12.3 social icon dark */
.wt-social-links.dark li {
  padding: 0 3px;
}
.wt-social-icon.dark li a {
  border: 1px solid #777777;
  color: #777777;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

/*12.4 social share icon style 1 */
.wt-share-icon li {
  border: none;
  width: 36px;
  display: block;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0;
}

.wt-share-icon li a {
  color: #fff;
}

.wt-share-icon li i {
  border-right: none;
  font-size: 14px;
  color: #fff;
  width: 36px;
  height: 34px;
  line-height: 34px;
  padding: 0;
  text-align: center;
}

.wt-share-icon li.fb {
  background: #354d89;
}
.wt-share-icon li.fb i {
  background: #3a5799;
}
.wt-share-icon li.gp {
  background: #d34b2b;
}
.wt-share-icon li.gp i {
  background: #e35736;
}
.wt-share-icon li.tw {
  background: #029fdf;
}
.wt-share-icon li.tw i {
  background: #00abf0;
}
.wt-share-icon li.dig {
  background: #1d61aa;
}
.wt-share-icon li.dig i {
  background: #2b6fb8;
}
.wt-share-icon li.lin {
  background: #0176be;
}
.wt-share-icon li.lin i {
  background: #0082ca;
}
.wt-share-icon li.pin {
  background: #ac0104;
}
.wt-share-icon li.pin i {
  background: #bd0e15;
}
.wt-share-icon li:hover {
  width: 90px;
}

/*-------------------------------------
	13. Breadcrumb 
-------------------------------------*/

/*13.1 breadcrumb-row-1*/

.wt-breadcrumb {
  margin: 0;
  list-style: none;
}
.wt-breadcrumb li {
  padding: 0;
  margin-right: 8px;
  color: #fff;
  position: relative;
  display: inline-block;
  font-size: 12px;
}
.wt-breadcrumb li a {
  color: #fff;
}
.wt-breadcrumb li a:hover {
  color: #ffc905;
}
.wt-breadcrumb li:last-child {
  color: #ffc905;
}
.wt-breadcrumb li:first-child a i {
  font-size: 18px;
  vertical-align: text-top;
}
.wt-breadcrumb li:last-child:after {
  display: none;
}

/*13.2 Breadcrumb-1*/
.breadcrumb-style-2 li:after {
  content: '\f101';
  margin-left: 8px;
  font-family: 'FontAwesome';
  color: #fff;
  font-size: 16px;
  font-weight: normal;
}

/*-------------------------------------
	14. Google map 
-------------------------------------*/
.google-map {
  width: 100%;
  height: 460px;
}

/*-------------------------------------
	16. Countdown clock 
-------------------------------------*/
.count-row {
  background: #333;
}
#countdown-clock {
  display: table;
  text-align: center;
  width: 80%;
  margin-left: -15px;
  border: 1px solid #3f3f3f;
  margin: 17px 0;
}
#countdown-clock span {
  display: table-cell;
  width: 1%;
  color: #fff;
  padding: 5px 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #999;
  border-right: 1px solid #3f3f3f;
}
#countdown-clock span:last-child {
  border-right: none;
}

#countdown-clock span b {
  display: block;
  font-size: 25px;
  font-weight: 900;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  #countdown-clock {
    width: 100%;
  }
}

/*-------------------------------------
	17. Form elements 
-------------------------------------*/
label {
  font-weight: 600;
  margin-bottom: 10px;
}
.panel-default,
.panel-default > .panel-heading {
  border-color: #e7ecf1;
  color: inherit;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e7ecf1;
}
.form-group {
  margin-bottom: 25px;
}
.form-control {
  border-color: #e1e6eb;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  height: 40px;
  font-size: 13px;
  line-height: 20px;
  padding: 9px 12px;
}
.form-control:focus {
  border-color: #e1e1e1;
  outline: 0;
}

.form-control:focus,
.has-error .form-control:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control.kv-fileinput-caption {
  height: 39px;
}
.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
  margin-right: 15px;
}

/* 17.1 input group */
.input-group {
  width: 100%;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell;
}
.input-group-addon {
  background: #fff;
  border-color: #e1e1e1;
  padding: 6px 14px;
  font-size: 16px;
}
.input-group-addon.font-size-20 {
  font-size: 20px;
}
.input-group-addon.fixed-w {
  text-align: center;
  padding: 6px 0;
  width: 40px;
}
.input-group-addon.v-align-t {
  line-height: 30px;
}
/* 17.3 radio & checkbox */
input[type='checkbox'],
input[type='radio'] {
  opacity: 0;
  margin-left: 1px !important;
}
input[type='checkbox'] + label,
input[type='radio'] + label {
  display: block;
  padding-left: 20px !important;
  position: relative;
}
input[type='checkbox'] + label:hover:before,
input[type='radio'] + label:hover:before {
  border-color: #3396d1;
}
input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  background-color: #fff;
  border: 2px solid #b6b7b8;
  border-radius: 0px;
  content: '';
  display: inline-block;
  height: 16px;
  line-height: 1;
  margin-left: -20px;
  position: absolute;
  top: 2px;
  transition: all linear 0.1s;
  width: 16px;
}
input[type='checkbox'] + label:after {
  color: #fff;
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 0px;
  -webkit-font-smoothing: antialiased;
  left: 6px;
  position: absolute;
  top: 7px;
  transition: all linear 0.1s;
}
input[type='checkbox']:checked + label:before {
  border-width: 7px;
  border-color: #3396d1;
}
input[type='checkbox']:checked + label:after {
  font-size: 10px;
  left: 2px;
  top: 2px;
}
input[type='radio'] + label:before {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  content: '';
}

input[type='radio']:checked + label:before {
  border-color: #3396d1;
  border-width: 5px;
}
.form-inline .checkbox input[type='checkbox'],
.form-inline .radio input[type='radio'] {
  position: absolute;
  left: 0;
  bottom: 0;
}

/*17.4 Browse Button css */
.btn-file {
  overflow: hidden;
  position: relative;
}
.btn-file input[type='file'] {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  cursor: inherit;
  display: block;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

/*17.8 validation symbol */
.has-feedback label ~ .form-control-feedback {
  top: 31px;
}

/*17.9 error for select box */
.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:hover,
.has-error .bootstrap-select .dropdown-toggle:focus {
  border-color: #a94442 !important;
}

/*17.10 succes for select box */
.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:hover,
.has-success .bootstrap-select .dropdown-toggle:focus {
  border-color: #3c763d !important;
}

/*17.11 textarea */

/*17.11(1) resize-vertical*/
.resize-vertical {
  resize: vertical;
}

/*17.11(2) resize-horizontal*/
.resize-horizontal {
  resize: horizontal;
}

/*17.11(3) resize-none*/
.resize-none {
  resize: none;
}

/*-------------------------------------
	18. Loading 
-------------------------------------*/
/*18.1 loading - 1*/

.loading-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: hidden;
}
.loading-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 9999;
}

.loading-pic {
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 99999;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*18.2 loading - 2*/
.loading-cover,
.loading-srh-bar,
.loading-map {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.loading-cover .fa-spinner,
.loading-srh-bar .fa-spinner,
.loading-map .fa-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px;
  font-size: 25px;
  color: #333;
}

/*-------------------------------------
	19. Widgets 
-------------------------------------*/

.widget {
  margin-bottom: 40px;
}
.widget-title {
  margin: 0 0 25px;
}
.widget-title {
  margin-bottom: 20px;
}

.recent-posts-entry ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

/* 19.1 widget listing*/
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.widget_services ul li {
  padding-bottom: 18px;
  margin-bottom: 13px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  padding: 10px 10px 10px 15px;
  margin-bottom: 0;
  line-height: 20px;
}
.widget_services ul li {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
}

.widget_services ul li a {
  color: #000;
}
.widget_services ul li a:hover {
  color: #ababab;
}

.widget_services ul li:before {
  content: '\f101';
  position: absolute;
  left: 0;
  top: 10px;
  display: block;
  font-family: 'FontAwesome';
}

.widget_services li li {
  border-bottom: none;
  padding-left: 25px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 28px;
}
.widget_services li li:before {
  top: 0;
  left: 1px;
}

.widget_services.inline-links li {
  display: inline-block;
  border-bottom: none;
  margin-right: 20px;
}
/*19.2 widget search custom*/
.search-bx .btn {
  border-left-color: #fff;
  padding: 9px 10px;
  color: #3396d1;
}

/*19.3 widget search wp-default*/
.widget_search .screen-reader-text {
  display: block;
}
.searchform {
  position: relative;
}
.searchform input[type='text'] {
  width: 100%;
  height: 40px;
  padding: 10px 90px 10px 15px;
  border: 1px solid #cccccc;
}
.searchform input[type='submit'] {
  height: 40px;
  padding: 10px 15px;
  background-color: #ffbc13;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ffffff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}
.searchform input[type='submit']:hover,
.searchform input[type='submit']:focus,
.searchform input[type='submit']:active {
  background-color: #6ab33e;
  color: #ffffff !important;
  border-bottom-color: #5a9e2f;
}

/*19.4 widget recent-posts*/
.recent-posts-entry .post-date,
.tweets-feed-entry .tweet-date {
  color: #3396d1;
  font-style: normal;
}
.widget .post-title {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 8px;
  text-transform: uppercase;
  margin-top: 0px;
  letter-spacing: 2px;
}
.recent-posts-entry .widget-post {
  margin-bottom: 5px;
  padding: 5px;
}
.recent-posts-entry .widget-post-bx:last-child {
  border-bottom: none;
}
.recent-posts-entry .widget-post:last-child {
  margin-bottom: 0px;
}
.recent-posts-entry .wt-post-media {
  float: left;
  width: 65px;
}
.recent-posts-entry .wt-post-info {
  background: transparent;
  padding: 0;
  margin-left: 80px;
  border: none;
}
.recent-posts-entry .post-meta span {
  margin-right: 10px;
}

/*19.8 widget tags-list*/
.widget_tag_cloud a {
  padding: 8px 14px;
  background-color: #f6f7f8;
  font-size: 12px;
  display: inline-block;
  margin: 0px 2px 5px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
}
.widget_tag_cloud a:hover {
  background-color: #000;
  color: #fff;
}

/* 19.14 widget gallery */
.widget_gallery ul {
  padding-left: 0;
}
.widget_gallery li {
  display: inline-block;
  margin-bottom: 3px;
  width: 23.9%;
  background-color: #000;
  margin-right: 3px;
}
.widget_gallery li a {
  position: relative;
}
.widget_gallery li a img {
  width: 100%;
}
.widget_gallery li a:hover {
  cursor: crosshair;
}
.widget_gallery li:hover img {
  margin-bottom: 0;
  opacity: 0.3;
}
.widget_gallery li:hover a:after {
  opacity: 1;
}
.widget_gallery a {
  display: block;
}
@media only screen and (max-width: 991px) {
  .widget_gallery li {
    width: 24.333%;
  }
}
@media only screen and (max-width: 480px) {
  .widget_gallery li {
    width: 32.1%;
  }
}
.widget_newsletter-2 .newsletter-bx {
  background-color: #f6f7f8;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.widget_newsletter-2 .newsletter-bx .newsletter-icon {
  position: absolute;
  right: 5px;
  top: -5px;
}
.widget_newsletter-2 .newsletter-bx .newsletter-icon i {
  font-size: 75px;
  z-index: 0;
  color: rgba(0, 0, 0, 0.05);
}
/* 19.18 widget client slider */
.widget-client {
  background-color: #f6f7f8;
}
.widget-client.owl-carousel .owl-dots {
  position: absolute;
  right: 15px;
  margin-top: 0px;
  top: 50%;
  width: 0px;
  transform: translateX(-50%) translateY(-50%);
}
.widget-client.owl-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 3px;
  border: none;
  float: right;
  clear: both;
  position: relative;
}
.widget-client.owl-carousel .owl-dots .owl-dot span:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 3px;
  background-color: #000;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.widget-client.owl-carousel .owl-dots .owl-dot.active span:after {
  width: 50px;
}
.widget-client .ow-client-logo {
  background-color: #f6f7f8;
}

/*-------------------------------------
	20. Table 
-------------------------------------*/

/* 20.1 Full-responsive */
.wt-responsive-table td,
.wt-responsive-table th {
  padding: 8px !important;
}

@media only screen and (max-width: 800px) {
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: none;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
  }
  #no-more-tables td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left;
  }
  #no-more-tables td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  #no-more-tables td:before {
    content: attr(data-title);
  }
}

/*-------------------------------------
	21. Video 
-------------------------------------*/

.yt-thum-box img {
  margin: -9.5% 0%;
}

/*======================================
12. WP DEFAULT  
======================================*/

.alignnone {
  margin: 0;
}
.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 15px;
}

.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}
.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}
a img.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}
a img.alignnone {
  margin: 5px 0 25px 0;
}
a img.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}
a img.aligncenter {
  display: block;
  margin: 5px auto 15px;
}
.wp-caption {
  max-width: 100%;
  text-align: center;
}
.wp-caption img[class*='wp-image-'] {
  display: block;
  margin: 0;
}
.wp-caption.alignnone {
  margin: 5px 0 25px 0;
}
.wp-caption.alignleft {
  margin: 5px 25px 25px 0;
}
.wp-caption.alignright {
  margin: 5px 0 25px 25px;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  color: #9d9d9d;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  padding: 10px 0;
  text-align: left;
}
blockquote {
  padding: 30px 20px 30px 80px;
  font-size: 15px;
  font-style: italic;
  color: #000;
  margin: 40px 0;
  line-height: 30px;
  position: relative;
  background: #f6f7f8;
  clear: both;
  border: none;
  font-family: 'Crete Round', serif;
}

blockquote p {
  font-size: 12px;
  color: #000;
  line-height: 30px;
  margin-bottom: 0px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 6px;
}
blockquote:before {
  content: '\f10d';
  font-family: 'FontAwesome';
  color: #555555;
  font-size: 35px;
  position: absolute;
  left: 20px;
  top: 38px;
  font-style: normal;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

/*======================================
13. BLOG  
======================================*/

.blog-post {
  position: relative;
  margin-bottom: 40px;
}
.wt-post-title {
  margin-bottom: 15px;
}
.wt-post-title .post-title {
  margin: 0;
  text-transform: uppercase;
  font-weight: normal;
}

.wt-post-meta {
  margin-bottom: 10px;
}
.wt-post-meta ul {
  margin: 0px;
  list-style: none;
}
.wt-post-meta li {
  margin-right: 5px;
  padding: 0;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.wt-post-meta i {
  margin: 0 5px;
}
.wt-post-meta li:after {
  content: '/';
  display: inline-block;
  font-weight: normal;
  margin-left: 5px;
  opacity: 0.5;
}
.wt-post-meta li:last-child:after {
  display: none;
}
.wt-post-text {
  margin-bottom: 15px;
  text-align: left;
}
.wt-post-text p:last-child {
  margin: 0;
}
.wt-post-readmore {
  margin-bottom: 15px;
}
.wt-post-readmore a {
  font-family: 'Crete Round', serif;
  font-size: 16px;
  font-style: italic;
  text-decoration: underline;
}

@media only screen and (max-width: 420px) {
  .masonry-item .wt-post-info {
    padding-bottom: 0px;
  }
  .masonry-item .wt-post-title h2.post-title {
    line-height: 14px;
  }
  .masonry-item .wt-post-title h2.post-title a {
    font-size: 14px;
    line-height: none;
  }
  .news-grid .wt-post-text {
    display: none;
  }
}

/*-------------------------------------
	3. if no image with blog post
-------------------------------------*/
.blog-md .wt-post-media {
  width: 650px;
}
.blog-md .wt-post-media img {
  width: 100%;
}
.blog-md .wt-post-info {
  border: none;
}
.blog-md .wt-post-tags {
  border: none;
  display: inline-block;
  padding: 0;
}

.blog-md:nth-child(odd) .wt-post-media {
  float: left;
  margin-right: 30px;
}
.blog-md:nth-child(even) .wt-post-media {
  float: right;
  margin-left: 30px;
}

.blog-md:nth-child(odd) .wt-post-info,
.blog-md:nth-child(even) .wt-post-info {
  padding-right: 20px;
  padding-left: 20px;
}

@media only screen and (max-width: 991px) {
  .blog-md .wt-post-media {
    width: 100%;
  }

  .blog-md:nth-child(odd) .wt-post-info,
  .blog-md:nth-child(even) .wt-post-info {
    float: none;
    clear: both;
  }
  .blog-md:nth-child(odd) .wt-post-media {
    margin-right: 0px;
  }
  .blog-md:nth-child(even) .wt-post-media {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-md.blog-post .wt-post-media {
    float: none;
    margin: 0 0 20px;
    width: auto;
  }
}

/*-------------------------------------
	10. side bar
-------------------------------------*/
.side-bar .widget {
  margin-bottom: 30px;
}
.side-bar .widget:last-child {
  margin-bottom: 0px;
}
.widget .widget-post img {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .side-bar {
    margin-bottom: 0px;
    margin-top: 30px;
  }
}

/*-------------------------------------
	12. comment list
-------------------------------------*/
.comments-area {
  padding: 0;
}
.comments-area .comments-title {
  text-transform: uppercase;
  font-size: 20px;
}
ol.comment-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
ol.comment-list li.comment {
  position: relative;
  padding: 0;
}
ol.comment-list li.comment .comment-body {
  position: relative;
  padding: 20px 30px 20px 50px;
  margin-bottom: 40px;
  margin-left: 40px;
  color: #000;
  position: relative;
}
ol.comment-list li.comment .comment-author {
  display: block;
  margin-bottom: 10px;
}
ol.comment-list li.comment .comment-author .avatar {
  position: absolute;
  top: 20px;
  left: -40px;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  padding: 5px;
  background-color: #fff;
}
ol.comment-list li.comment .comment-author .fn {
  display: inline-block;
  color: #000;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal;
}
ol.comment-list li.comment .comment-author .says {
  display: none;
  color: #999999;
  font-weight: 600;
}
ol.comment-list li.comment .comment-meta {
  color: #000;
  margin-bottom: 15px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}
ol.comment-list li.comment .comment-meta a {
  color: #000;
}

ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
  font-family: 'FontAwesome';
  font-size: 16px;
  vertical-align: top;
}

ol.comment-list li.comment p {
  line-height: 24px;
  margin: 0 0 5px;
  font-weight: 400;
  font-size: 12px;
}
ol.comment-list li.comment .reply a {
  position: absolute;
  top: 50px;
  right: 30px;
  margin-top: -5px;
  font-size: 11px;
  color: #000;
}
ol.comment-list li .children {
  list-style: none;
  margin-left: 80px;
}
ol.comment-list li .children li {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comments-area .padding-30 {
    padding: 15px;
  }
  ol.comment-list li.comment .comment-body {
    margin-bottom: 30px;
    margin-left: 30px;
  }
  ol.comment-list li.comment .comment-author .avatar {
    left: -35px;
    height: 70px;
    width: 70px;
  }
  ol.comment-list li .children {
    margin-left: 20px;
  }
  ol.comment-list li.comment .reply a {
    position: static;
  }
}
@media only screen and (max-width: 480px) {
  ol.comment-list li.comment .comment-body {
    margin-left: 26px;
  }
  ol.comment-list li.comment .comment-author .avatar {
    left: auto;
    top: auto;
    width: 60px;
    height: 60px;
    position: inherit;
  }
  ol.comment-list li.comment .comment-body {
    padding: 0px;
    margin-left: 0px;
  }
  ol.comment-list li .children {
    margin-left: 0px;
  }
}
/*-------------------------------------
	13. comment form
-------------------------------------*/
.comment-respond .comment-reply-title {
  text-transform: uppercase;
  font-size: 20px;
}
.comments-area .comment-form {
  margin: 0 -15px;
}
.comments-area .comment-form .comment-notes {
  display: none;
}
.comments-area .comment-form p {
  width: 33.333%;
  float: left;
  padding: 0 15px;
  margin-bottom: 30px;
  position: relative;
  font-weight: 600;
}
.comments-area .comment-form p.form-allowed-tags {
  width: 100%;
}
ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0 15px !important;
}
.comments-area .comment-form p label {
  display: none;
  line-height: 18px;
  margin-bottom: 10px;
}

.comments-area .comment-form p input[type='text'],
.comments-area .comment-form p textarea {
  width: 100%;
  height: 40px;
  padding: 10px 0px;
  border: none;
  background-color: transparent;
  border-bottom: 4px solid #cccccc;
}
.comments-area .comment-form p.comment-form-comment {
  width: 100%;
  display: block;
  clear: both;
}
.comments-area .comment-form p textarea {
  height: 120px;
  resize: none;
}
.comments-area .comment-form p.form-submit {
  clear: both;
  float: none;
  width: 100%;
  margin: 0;
}
.comments-area .comment-form p input[type='submit'] {
  background-color: #77c04b;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-width: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 10px 20px;
  text-transform: uppercase;
}
.comments-area .comment-form p input[type='submit']:hover,
.comments-area .comment-form p input[type='submit']:focus,
.comments-area .comment-form p input[type='submit']:active {
  background-color: #6ab33e;
  border-color: #6ab33e;
  color: #fff;
}
.comment-respond .form-submit .site-button {
  font-weight: 300;
  padding: 15px 30px;
}

.comment-form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}
.comment-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}
.comment-form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}
.comment-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-form p {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .comment-respond {
    padding: 20px;
  }
}

@media only screen and (max-width: 1280px) {
  .wt-post-info-inner {
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .wt-post-info-inner {
    height: auto;
  }
}
/*-------------------------------------
	15. blog grid-post
-------------------------------------*/

.blog-post .grid-post li {
  float: left;
  width: 50%;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  list-style: none;
}

.blog-post .grid-post li .portfolio-item {
  width: 100%;
}
.portfolio-item {
  width: 100%;
  position: relative;
  overflow: hidden;
  color: #fff;
  width: 100%;
  border: 0;
  position: relative;
}
.blog-post .grid-post li img {
  width: 100%;
}

/*======================================
14. HEADER (top part of page)
======================================*/
.site-header {
  position: relative;
  z-index: 999;
}
@media only screen and (max-width: 1200px) {
  .site-header .container {
    width: auto;
  }
}
.site-header ul,
.site-header ol {
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .sticky-wrapper {
    height: auto !important;
  }
}
/*-------------------------------------
	1. header full screen
-------------------------------------*/
.nav-wide .container {
  width: 100%;
}
.nav-wide .container {
  padding: 0px 80px;
}
@media only screen and (max-width: 991px) {
  .nav-wide .container {
    padding: 0px 15px;
  }
}
/*-------------------------------------
	2. without top bar
-------------------------------------*/
.extra-nav {
  display: table;
  float: right;
  height: 70px;
  position: relative;
  z-index: 9;
}
.extra-nav .extra-cell {
  display: table-cell;
  padding-left: 10px;
  vertical-align: middle;
}

/*-------------------------------------
	3. map page header
-------------------------------------*/
#header-part.fix-map-header {
  height: 90px;
}
#header-part.fix-map-header .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

@media only screen and (max-width: 480px) {
  #header-part.fix-map-header {
    height: 160px;
  }
}

/*======================================
16. LOGO ( company identify  symbol )
======================================*/

/*-------------------------------------
	1. logo for header 
-------------------------------------*/
.logo-header {
  display: table;
  float: left;
  width: 140px;
  height: 70px;
  vertical-align: middle;
  padding: 0;
  font-size: 36px;
  color: #1bbce8;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
}
.logo-header-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.logo-header-inner img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 991px) {
  .logo-header {
    width: 120px;
  }
}
@media only screen and (max-width: 480px) {
  .logo-header {
    width: 100px;
  }
}

.logo-footer {
  display: table;
  width: 140px;
  height: 70px;
  vertical-align: middle;
  padding: 0;
  font-size: 36px;
  color: #1bbce8;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
}
.logo-footer-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.logo-footer-inner img {
  max-width: 100%;
  max-height: 100%;
}
/*-------------------------------------
	2. when header is sticky 
-------------------------------------*/
.is-fixed .logo-header {
  height: 70px;
}

/*======================================
17. MAIN BAR ( place logo & main-navigation )
======================================*/
.top-bar {
  color: #000;
  padding: 0px;
  /*background-color:#212427;*/
}
.top-bar .fa {
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.01);
  padding: 3px;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
}

.wt-topbar-left,
.wt-topbar-right,
.wt-topbar-center {
  padding-left: 15px;
  padding-right: 15px;
}
.wt-topbar-left {
  float: left;
}
.wt-topbar-right {
  float: right;
}
.wt-topbar-center {
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .wt-topbar-left,
  .wt-topbar-right {
    float: none;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
/*-------------------------------------
	1. eamil-phone (left) 
-------------------------------------*/
.e-p-bx li {
  display: inline;
  font-size: 14px;
  padding-right: 10px;
}
.e-p-bx li a {
  color: #fff;
}
.e-p-bx li i {
  margin-right: 5px;
  vertical-align: middle;
}

/*-------------------------------------
	2. social-links (right) 
-------------------------------------*/
.social-bx,
.login-bx {
  margin: 0 0 0 15px;
  float: right;
}
@media only screen and (max-width: 480px) {
  .social-bx,
  .e-p-bx,
  .login-bx {
    margin-top: 5px;
    margin-bottom: 5px !important;
  }
}
.social-bx li,
.login-bx li {
  font-size: 12px;
  margin: 0;
  position: relative;
}
.social-bx li {
  padding-left: 2px;
  padding-right: 2px;
}
.social-bx li a,
.login-bx li a {
  color: #000;
  font-size: 14px;
}
.social-bx li i,
.login-bx li i {
  vertical-align: baseline;
  margin: 0 5px 0 0;
}
.social-bx li span,
.login-bx li span {
  vertical-align: baseline;
  margin: 0 2px 0;
  font-size: 8px;
}
.login-bx li ul {
  top: 25px;
  left: auto;
  right: 0;
  border: none;
  padding: 0;
}
.login-bx li ul li {
  border-bottom: 1px solid #f7f7f7;
}
.login-bx li ul li a {
  color: #767676;
  padding: 9px 15px;
}
.arrow-up-border:before,
.arrow-up:after {
  position: absolute;
  display: inline-block;
  content: '';
}
.arrow-up-border:before {
  top: -7px;
  right: 19px;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #f00;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.arrow-up:after {
  top: -6px;
  right: 20px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
}

.arrow-left:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.arrow-left:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}
/*------------- top share icon block ----------------------*/

.share-icon-btn .dropdown-menu {
  padding: 0px 20px;
  min-width: 60px;
  top: 53px;
  left: -10px;
}
.share-icon-btn .social-bx {
  clear: both;
  float: none;
  margin-left: 0px;
}
.share-icon-btn .social-bx.list-inline > li {
  display: block;
  margin: 10px 0px;
}

.share-icon-btn2 .dropdown-menu {
  padding: 0px 20px;
  min-width: 60px;
  top: 53px;
  left: -10px;
}
.share-icon-btn2 .social-bx {
  clear: both;
  float: none;
  margin-left: 0px;
}
.share-icon-btn2 .social-bx.list-inline > li {
  display: block;
  margin: 10px 0px;
}
.share-icon-btn2 {
  display: none;
}

@media only screen and (max-width: 991px) {
  .e-p-bx {
    display: none;
  }
  .header-top-info .wt-topbar-right {
    padding-left: 0px;
    padding-right: 0px;
  }
  .header-top-info .social-bx {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .share-icon-btn2 {
    display: block;
  }
  .header-top-info {
    display: none;
  }
}
/*-----------------------------------*/

.main-bar {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.main-bar::after,
.main-bar::after {
  clear: both;
  content: '';
  display: table;
}
/*main bar with box view*/

@media only screen and (max-width: 480px) {
  .main-bar {
    padding-left: 0px;
    padding-right: 0px;
  }
}
/*header info css*/
.header-info {
  float: right;
  padding: 10px 0;
}
.header-info > ul {
  list-style: none;
  display: table;
}
.header-info > ul li {
  display: table-cell;
  padding: 15px 10px;
  vertical-align: middle;
}
.header-info > ul li .icon-sm {
  float: left;
  margin-right: 15px;
}
.header-info > ul li strong {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  color: #1a1a1a;
}
@media only screen and (max-width: 991px) {
  .header-info > ul li {
    display: none;
  }
  .header-info > ul li.btn-col-last {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-info > ul li.btn-col-last,
  .header-info {
    display: none;
  }
}
.header-botton {
  background-color: #1a1a1a;
}
.header-botton .header-nav .nav {
  float: none;
}
.header-botton .header-nav .nav > li > a {
  padding: 20px 12px;
  color: #fff;
}
.header-botton .extra-nav {
  padding: 13px 0;
}
.header-botton .extra-nav .site-search-btn {
  color: #fff;
  padding: 7px 15px;
}
.header-botton .extra-nav .wt-cart .woo-cart-count {
  border: 2px solid #fff;
  color: #fff;
  min-width: 36px;
  padding: 5px 5px 5px;
}
.header-botton .extra-nav .wt-cart .woo-cart-count::before {
  border-color: #fff;
}
@media only screen and (max-width: 991px) {
  .header-botton .navbar-toggle {
    float: left;
    margin: 9px 0;
  }
  .header-botton .navbar-toggle span {
    background-color: #fff;
  }
  .header-botton .header-nav .nav > li > a {
    padding: 12px 12px;
    color: #777;
  }
}
@media only screen and (max-width: 767px) {
  .header-middle .logo-header {
    float: none;
    margin: 9px auto;
  }
}

/*-------------------------------------
	1. Top Part Search Bar
-------------------------------------*/
.site-search-btn {
  background: none;
  border: none;
  outline: none;
  color: #000;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .site-search-btn {
    padding: 0px 15px;
  }
}
#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  display: none;
}

#search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  z-index: 999;
  display: block;
}

#search form {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -51px;
  width: 60%;
  margin-left: 20%;
  color: #fff;
  background: #000;
  font-size: 40px;
  font-weight: 300;
  text-align: left;
  outline: none;
  padding: 10px;
  border-radius: 0px;
}
#search input[type='search'] {
  width: 100%;
  background: none;
  border: none;
  padding: 0px 30px;
}
#search .search-btn {
  border: none;
  background: none;
  padding: 0px 30px;
}
#search .search-btn i {
  font-size: 36px;
}
#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  opacity: 1;
  font-size: 27px;
  color: #fff;
  width: 30px;
  height: 30px;
}
#search .close:after,
#search .close:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 32px;
  background-color: #fff;
  right: 20px;
  top: 0px;
}
#search .close:after {
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari */
  transform: rotate(45deg);
}
#search .close:before {
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Safari */
  transform: rotate(-45deg);
}
#search .close:hover:after,
#search .close:hover:before {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  #search form {
    width: 90%;
    margin-left: 5%;
    font-size: 26px;
  }
  #search input[type='search'] {
    padding: 0px 0px 0px 30px;
  }
  #search .search-btn {
    padding: 0px 15px;
  }
  #search .search-btn i {
    font-size: 24px;
  }
}

@media only screen and (max-width: 400px) {
  #search form {
    font-size: 16px;
  }
  #search input[type='search'] {
    padding: 0px 0px 0px 10px;
  }
  #search .search-btn {
    padding: 0px 15px;
  }
  #search .search-btn i {
    font-size: 18px;
  }
}
.wt-quik-search .form-control::-moz-placeholder {
  color: #fff;
}
.wt-quik-search .form-control:-moz-placeholder {
  color: #fff;
}
.wt-quik-search .form-control:-ms-input-placeholder {
  color: #fff;
}
.wt-quik-search .form-control::-webkit-input-placeholder {
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .site-search {
    width: 100%;
  }
  .site-search .site-button {
    padding: 0;
  }
  .site-search form {
    padding-left: 0;
  }
}
/*======================================
18. NAVIGATIONS 
======================================*/

/*-------------------------------------
	1. mega-menu
-------------------------------------*/
.navbar-toggle {
  border: none;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 15px;
  margin-right: -10px;
  padding: 12px 10px;
}
.navbar-toggle .icon-bar {
  background: #000;
  height: 3px;
  border-radius: 0;
  -webkit-border-radius: 0;
}
@media only screen and (max-width: 480px) {
  .navbar-toggle {
    margin-left: 10px;
  }
  .extra-nav .extra-cell {
    margin-left: 0;
  }
}
.is-fixed .navbar-toggle {
  margin-top: 14px;
  margin-bottom: 14px;
}
.header-nav {
  position: relative;
  padding: 0;
}
.header-nav .nav {
  float: right;
}
.header-nav .nav i {
  font-size: 9px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: middle;
  opacity: 0.7;
}
.header-nav .nav > li {
  font-weight: 400;
  position: relative;
  text-transform: uppercase;
  margin: -10px 0;
  padding: 10px 0;
}
.header-nav .nav > li > a {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  color: #000;
  font-size: 12px;
  padding: 35px 12px;
  cursor: pointer;
  letter-spacing: 2px;
  margin: -10px 0;
}
.header-nav .nav > li > a:hover {
  background-color: transparent;
  color: #212427;
}

.header-nav .nav > li > a:active,
.header-nav .nav > li > a:focus {
  background-color: transparent;
}

.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a {
  background-color: transparent;
  color: #ababab;
}

.header-nav .nav > li:hover > a {
  color: #ababab;
}

.header-nav .nav > li:hover > .sub-menu,
.header-nav .nav > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-nav .nav > li .sub-menu {
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  display: block;
  left: 0;
  top: 100%;
  list-style: none;
  margin: 20px 0 0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
}

.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  text-transform: none;
}

.header-nav .nav > li .sub-menu li a {
  color: #000;
  display: block;
  font-size: 12px;
  padding: 10px 20px;
}
.header-nav .nav > li .sub-menu li a:hover {
  background-color: #f2f2f2;
  color: #000;
  text-decoration: none;
}
.header-nav .nav > li .sub-menu li:hover > a {
  color: #000;
}
.header-nav .nav > li .sub-menu li:last-child {
  border-bottom: 0px;
}
.header-nav .nav > li .sub-menu li .fa {
  color: #363636;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 12px;
}

.header-nav .nav > li .sub-menu li > .sub-menu {
  left: 220px;
  margin: 0 0 0 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-nav .nav > li .sub-menu li:hover > .sub-menu {
  left: 220px;
  margin: 0px;
  opacity: 1;
  top: -1px;
  visibility: visible;
}

.header-nav .nav > li .sub-menu li:hover > .sub-menu:before {
  background-color: transparent;
  bottom: 0px;
  content: '';
  display: block;
  height: 100%;
  left: -6px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.header-nav .nav > li.has-mega-menu {
  position: inherit;
}
.header-nav .nav > li .mega-menu li a > i {
  font-size: 13px;
  margin-right: 5px;
  min-width: 20px;
}
.header-nav .nav > li .mega-menu {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  display: table;
  left: 0px;
  list-style: none;
  opacity: 0;
  position: absolute;
  right: 0px;
  visibility: hidden;
  width: 100%;
  margin-top: 20px;
  z-index: 10;
}
.header-nav .nav > li .mega-menu > li {
  display: table-cell;
  padding: 10px 0;
  position: relative;
  vertical-align: top;
  width: 25%;
  text-transform: none;
}
.header-nav .nav > li .mega-menu > li:after {
  content: '';
  background-color: rgba(204, 204, 204, 0.4);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}
.header-nav .nav > li .mega-menu > li:last-child:after {
  display: none;
}
.header-nav .nav > li .mega-menu > li > a {
  color: #555555;
  display: block;
  font-size: 14px;
  padding: 0 20px;
}
.header-nav .nav > li .mega-menu > li ul {
  list-style: none;
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

.header-nav .nav > li .mega-menu > li ul a {
  color: #474747;
  display: block;
  font-size: 13px;
  line-height: 34px;
  padding: 2px 20px;
}
.header-nav .nav > li .mega-menu > li ul a:hover {
  color: #1bbce8;
  background-color: #f2f2f2;
}
.header-nav .nav > li.menu-item-has-children:before {
  content: '\f078';
  display: block;
  font-family: 'FontAwesome';
  right: 4px;
  position: absolute;
  top: 50%;
  color: #999;
  margin-top: -8px;
  font-size: 8px;
}
/*-- Submenu direction---*/
.header-nav .nav > li.submenu-direction .sub-menu {
  left: auto;
  right: 0;
}
.header-nav .nav > li.submenu-direction .sub-menu li > .sub-menu {
  left: auto;
  right: 220px;
  margin: 0 20px 0 0;
}
.header-nav .nav > li.submenu-direction .sub-menu li:hover > .sub-menu {
  left: auto;
  right: 220px;
  margin: 0 0 0 0;
}
.has-child .submenu-toogle {
  position: absolute;
  right: 4px;
  top: 0px;
  color: #000;
  background-color: transparent;
  z-index: 900;
  cursor: pointer;
  padding: 15px 10px;
  font-size: 14px;
  display: none;
  width: 100%;
  text-align: right;
}
.header-nav .has-child li .submenu-toogle {
  opacity: 0.9;
}

.header-nav
  .has-child.nav-active
  > a
  + .submenu-toogle.glyphicon-chevron-right:before {
  content: '\e114';
}
/*.header-nav .nav > li:last-child{display:none;}*/
@media only screen and (max-width: 991px) {
  header .logo {
    display: block;
    padding: 4px 0;
  }
  .header-nav .nav i {
    margin-top: 6px;
  }
  .header-nav .nav li.has-child.nav-active > a {
    color: #000;
  }
}

@media only screen and (max-width: 991px) {
  /* responsive strat when media screen [991px] css START*/
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }

  /* Responsive strat when medai screen [991px] css END*/
  .header-nav {
    clear: both;
    margin: 0 -15px;
    border-bottom: 1px solid #e9e9e9;
  }
  .header-nav .nav {
    float: none;
    margin: 0;
    background: #fff;
  }
  .header-nav .nav li {
    float: none;
  }

  .header-nav .nav > li .sub-menu > li,
  .header-nav .nav > li .mega-menu > li {
    float: none;
    display: block;
    width: auto;
  }
  .header-nav .nav li .sub-menu > li a {
    padding-left: 30px;
  }
  .header-nav .nav > li > a {
    padding: 12px 15px;
    border-top: 1px solid #e9e9e9;
    color: #777;
  }

  .header-nav .nav > li.active > a,
  .header-nav .nav > li.current-menu-item > a {
    color: #000;
  }
  .header-nav .nav i {
    float: right;
  }

  .header-nav .nav > li > a:hover,
  .header-nav .nav > li > a:active,
  .header-nav .nav > li > a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
  .header-nav .nav > li .mega-menu > li:after {
    display: none;
  }
  .header-nav .nav > li ul,
  .header-nav .nav > li .sub-menu,
  .header-nav .nav > li .mega-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
  .header-nav .nav > li ul.mega-menu ul {
    display: block;
  }
  .header-nav .nav > li .mega-menu > li {
    padding: 0px;
  }
  .header-nav .nav > li ul.mega-menu li:hover ul {
    display: block;
  }
  .header-nav .nav li.has-child {
    position: relative;
  }
  .header-nav .nav li .sub-menu,
  .header-nav .nav li .mega-menu {
    opacity: 1;
    margin: 0 !important;
    transition: none !important;
  }
  .has-child .submenu-toogle {
    display: block;
  }
}

/* Vertical Nav */
.nav-sidebar {
  position: fixed;
  right: -500px;
  top: 0;
  width: 100%;
  max-width: 500px;
  height: 100%;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.3);
}
.nav-siderbar-scroll {
  padding: 50px 40px;
  height: 100%;
  position: absolute;
  width: 100%;
  overflow: auto;
}
.nav-sidebar .vnav-btn {
  position: absolute;
  right: -40px;
  top: 200px;
  box-shadow: 7px 0 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 7px 0 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 7px 0 5px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 7px 0 5px rgba(0, 0, 0, 0.1);
}
.vnav-btn {
  background: #fff none repeat scroll 0 0;
  color: #333;
  cursor: pointer;
  display: block;
  font-size: 21px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  border: none;
}
.vnav-close {
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 10;
  width: 40px;
  height: 40px;
}

.vnav-close:after,
.vnav-close:before {
  content: '';
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 1px;
  background-color: #000;
}

.vnav-close:after {
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.vnav-close:before {
  background-color: #000;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*--vertcal navigation start--*/
.vertical-nav .has-child .submenu-toogle {
  position: absolute;
  right: 0px;
  top: 17px;
  color: #000;
  z-index: 999;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 16px;
  display: none;
  background: none;
}
.vertical-nav .has-child li .submenu-toogle {
  opacity: 0.9;
}
.vertical-nav
  .has-child.nav-active
  > a
  + .submenu-toogle.glyphicon-chevron-right:before {
  content: '\e114';
}

.vertical-content-wrap {
  display: table;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.vertical-content-area {
  display: table-cell;
}

.vertical-content-area.vertical-centered {
  vertical-align: middle;
}

.vertical-nav {
  font-family: 'Roboto Condensed', sans-serif;
}
.vertical-nav ul {
  list-style: none;
  float: none;
}
.vertical-nav ul li {
  float: none;
}
.vertical-nav ul li a > i {
  font-size: 18px;
  margin-right: 25px;
}
.vertical-nav ul > li > a {
  padding: 20px 10px;
  color: #999;
  display: block;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
.vertical-nav ul li a > i {
  font-size: 18px;
  margin-right: 20px;
}
.vertical-nav ul > li.active > a {
  color: #000;
}

.vertical-nav ul > li > a:hover,
.vertical-nav ul > li > a:active,
.vertical-nav ul > li > a:focus {
  background: none;
  text-decoration: none;
  color: #000;
}
.vertical-nav ul > li .mega-menu > li:after {
  display: none;
}

.vertical-nav ul > li:after {
  content: '';
  position: absolute;
  left: 15px;
  top: 30px;
  border-bottom: 1px dashed #000;
  height: 2px;
  width: 0%;
  z-index: -1;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.vertical-nav ul > li:hover:after {
  width: 90%;
}

.vertical-nav ul li .sub-menu > li a {
  font-size: 14px;
  padding: 5px 30px;
  text-transform: capitalize;
}
.vertical-nav ul li .sub-menu > li a > i {
  font-size: 20px;
}

.vertical-nav ul > li ul,
.vertical-nav ul > li .sub-menu {
  display: none;
  position: static;
  visibility: visible;
  width: auto;
}

.vertical-nav ul li.has-child {
  position: relative;
}
.vertical-nav ul li .sub-menu {
  opacity: 1;
  margin: 0 !important;
}
.vertical-nav .has-child .submenu-toogle {
  display: block;
}
.vertical-nav .has-child .submenu-toogle:hover {
  text-shadow: 0px 0px 8px rgb(0, 0, 0, 0.5);
}

.nav-transparent-area {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  left: 0;
  top: 0;
  display: none;
  cursor: pointer;
  z-index: 998;
}
/*-------------------------------------
	2. nav dark version
-------------------------------------*/
.nav-dark.header-nav .nav > li .sub-menu,
.nav-dark.header-nav .nav > li .mega-menu {
  background-color: #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.nav-dark.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid #23282f;
}
.nav-dark.header-nav .nav > li .sub-menu li:last-child {
  border-bottom: 0px;
}
.nav-dark.header-nav .nav > li .sub-menu li a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a,
.nav-dark.header-nav .nav > li .mega-menu > li > a {
  color: #cccccc;
}
.nav-dark.header-nav .nav > li .sub-menu li a:hover {
  background-color: #222;
}
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover {
  background-color: #23282f;
  color: #ffbc13;
}
.nav-dark.header-nav .nav > li .mega-menu > li:after {
  background-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
  .nav-dark.header-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  }
  .nav-dark.header-nav .nav {
    background-color: #2d3239;
  }
  .nav-dark.header-nav .nav > li > a {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .nav-dark.header-nav .nav > li > a {
    color: #999;
  }

  .nav-dark.header-nav .nav > li > a:hover,
  .nav-dark.header-nav .nav > li > a:active,
  .nav-dark.header-nav .nav > li > a:focus {
    background-color: #23282f;
    color: #ffbc13;
  }
  .nav-dark.header-nav .nav > li ul,
  .nav-dark.header-nav .nav > li .sub-menu,
  .nav-dark.header-nav .nav > li .mega-menu {
    background-color: #23282f;
  }
}
/*-------------------------------------
	3. when header is sticky
-------------------------------------*/
.sticky-no .main-bar {
  position: static !important;
}

/*-------------------------------------
	4. when header is sticky
-------------------------------------*/
.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
}

/*-------------------------------------
 5. header id Fixed
-------------------------------------*/
/*.is-fixed.color-fill .main-bar {    padding:0px 64px;}*/
.is-fixed.color-fill .header-nav .nav > li {
  margin: 0;
  padding: 0;
}
.is-fixed.color-fill .header-nav .nav > li > a {
  margin: 0;
  padding: 25px 12px;
}

@media only screen and (max-width: 767px) {
  .main-bar,
  .is-fixed .main-bar,
  .is-fixed.color-fill .main-bar {
    padding: 0px;
  }
}

@media only screen and (max-width: 991px) {
  /* .is-fixed .header-nav .nav{
	overflow:auto;
	height:275px;
}	 */
  /* .is-fixed .header-nav .nav > li > a {
	padding:12px 15px;
}
.is-fixed#header-part .navbar-toggle{
	margin:15px 0;
} */
}
@media only screen and (max-width: 768px) {
  .is-fixed .extra-nav {
    padding: 13px 7px;
  }
}
@media only screen and (max-width: 767px) {
  .is-fixed .extra-nav {
    padding: 13px 0px;
  }
}
/*-------------------------------------
	6. header with bg primary color 1
-------------------------------------*/
.bg-primary .header-nav .nav > li > a {
  color: #fff;
}

.bg-primary .header-nav .nav > li:hover > a,
.bg-primary .header-nav .nav > li.active > a,
.bg-primary .header-nav .nav > li.current-menu-item > a {
  color: #1a1a1a;
}

.bg-primary .site-search-btn,
.bg-primary .wt-cart .woo-cart-total,
.bg-primary .wt-cart .woo-cart-count {
  color: #fff;
}

.bg-primary .wt-cart .woo-cart-count,
.bg-primary .wt-cart .woo-cart-count::before {
  border-color: #fff;
}

.bg-primary .navbar-toggle .icon-bar {
  background-color: #fff;
}
@media only screen and (max-width: 991px) {
  .bg-primary .header-nav .nav > li > a {
    color: #777;
  }

  .bg-primary .header-nav .nav > li:hover > a,
  .bg-primary .header-nav .nav > li.active > a,
  .bg-primary .header-nav .nav > li.current-menu-item > a {
    color: #ffbc13;
  }

  .header-nav .nav > li {
    margin: 0;
    padding: 0;
  }
  .header-nav .nav > li > a {
    margin: 0;
    padding: 10px 12px;
    font-weight: 600;
  }
  .is-fixed.color-fill .header-nav .nav > li {
    margin: 0;
    padding: 0;
  }
  .is-fixed.color-fill .header-nav .nav > li > a {
    margin: 0;
    padding: 10px 12px;
  }
}
/*======================================
19. BANNER
======================================*/

/*-------------------------------------
	1. inner page banner 
-------------------------------------*/
.wt-bnr-inr {
  height: 500px;
  background-size: cover;
  background-position: center center;
  display: table;
  width: 100%;
}
.wt-bnr-inr .container {
  display: table;
  height: 100%;
}
.wt-bnr-inr-entry {
  display: table-cell;
  vertical-align: bottom;
}

.wt-bnr-inr-entry .banner-title-outer {
  position: relative;
  padding: 70px 0px 70px 30px;
  border: 10px solid #fff;
  border-right: 0px;
  width: 200px;
}

.wt-bnr-inr-entry .banner-title-outer:after,
.wt-bnr-inr-entry .banner-title-outer:before {
  content: '';
  position: absolute;
  right: 0px;
  width: 10px;
  height: 50px;
  background-color: #fff;
}
.wt-bnr-inr-entry .banner-title-outer:after {
  top: 0px;
}
.wt-bnr-inr-entry .banner-title-outer:before {
  bottom: 0px;
  right: 0px;
}

.wt-bnr-inr-entry .banner-title-name {
  width: 640px;
}
@media only screen and (max-width: 991px) {
  .wt-bnr-inr {
    height: 330px;
    padding-top: 120px;
  }
}

@media only screen and (max-width: 768px) {
  .wt-bnr-inr-entry .banner-title-outer {
    width: auto;
    border: 0px;
    padding: 0px;
  }
  .wt-bnr-inr .container {
    width: 97%;
  }
  .wt-bnr-inr-entry .banner-title-outer::before {
    left: 30%;
    right: auto;
  }
  .wt-bnr-inr-entry .banner-title-outer::after {
    left: 30%;
    right: auto;
  }
  .wt-bnr-inr-entry .banner-title-name {
    width: auto;
    padding: 70px 0px 70px 30px;
    position: relative;
  }
  .wt-bnr-inr-entry .banner-title-name:before {
    border: 10px solid #fff;
    border-right: 0px;
    content: '';
    border-right: 0px;
    content: '';
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 30%;
    position: absolute;
  }
}

.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
  color: #fff;
}

/*======================================
20. SLIDER
======================================*/

/*-------------------------------------
	1. owl slider 
-------------------------------------*/

/*-------------------------------------
	1.1  next pre btn
-------------------------------------*/
.owl-carousel .owl-nav {
  margin-top: 40px;
  text-align: center;
}

.owl-carousel .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

/*-------------------------------------
	1.2 owl dots button
-------------------------------------*/
.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
}
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  background: none;
  border: none;
}
.owl-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #1a1a1a;
  border: 1px solid #fff;
  display: block;

  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}
.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
  background: #979797;
}

/*-------------------------------------
	1.3 owl button top to center
-------------------------------------*/
.owl-btn-top-center .owl-nav,
.owl-btn-top-left .owl-nav,
.owl-btn-top-right .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
.owl-btn-top-center .owl-stage-outer,
.owl-btn-top-left .owl-stage-outer,
.owl-btn-top-right .owl-stage-outer {
  padding-top: 60px;
}
.owl-btn-top-center .owl-nav {
  text-align: center;
}

/*-------------------------------------
	1.4 owl button top to left
-------------------------------------*/
.owl-btn-top-left .owl-nav {
  text-align: left;
}

/*-------------------------------------
	1.5 owl button top to right
-------------------------------------*/
.owl-btn-top-right .owl-nav {
  text-align: right;
}

/*-------------------------------------
	1.6 owl button bottom to center [it is default position ]
-------------------------------------*/
.owl-btn-bottom-center .owl-nav {
  text-align: center;
}

/*-------------------------------------
	1.7 owl button bottom to left
-------------------------------------*/
.owl-btn-bottom-left .owl-nav {
  text-align: left;
}

/*-------------------------------------
	1.8 owl button bottom to right
-------------------------------------*/
.owl-btn-bottom-right .owl-nav {
  text-align: right;
}

/*-------------------------------------
	1.9 owl button vertical to center
-------------------------------------*/
.owl-btn-vertical-center .owl-nav {
  margin: 0px;
}

.owl-btn-vertical-center .owl-nav .owl-prev,
.owl-btn-vertical-center .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  margin: -15px 0;
  border: none;
}
.owl-btn-vertical-center .owl-nav .owl-prev {
  left: 0;
}
.owl-btn-vertical-center .owl-nav .owl-next {
  right: 0;
}

/*-------------------------------------
	1.10 owl button show on hover
-------------------------------------*/
.owl-btn-hover .owl-nav {
  opacity: 0;
}
.owl-btn-hover:hover .owl-nav {
  opacity: 1;
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

/*-------------------------------------
    1.11 owl slider button
-------------------------------------*/
.owl-prev,
.owl-next {
  background-color: #000 !important;
  color: #ffffff !important;
  padding: 8px 10px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  border: none;
}
.owl-prev {
  margin-right: 5px;
}
.owl-next {
  margin-left: 5px;
}

.owl-prev:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-next:hover,
.owl-next:active,
.owl-next:focus {
  background-color: #212121;
  border-color: #212121;
  color: #ffffff;
  opacity: 0.9;
}

/*-------------------------------------
	1.12 owl dots nav position
-------------------------------------*/

.owl-dots-bottom-left .owl-dots,
.owl-dots-bottom-right .owl-dots,
.owl-dots-bottom-center .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
}
.owl-dots-bottom-left .owl-dots {
  text-align: left;
}
.owl-dots-bottom-right .owl-dots {
  text-align: right;
}

/*======================================
21. PAGINATION 
======================================*/

/*1. pagination style-1 */
.pagination-bx .pagination,
.cvf-universal-pagination .pagination {
  margin: 0;
}
.pagination {
  padding: 10px 0;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.pagination > li > a,
.pagination > li > span {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: #767676;
  font-weight: 600;
  font-size: 12px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #000;
  border-color: transparent;
  color: #fff;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #000;
  border-color: transparent;
}
.pagination > .previous > a,
.pagination > .next > a {
  font-size: 12px;
}

/*======================================
22. FOOTER 
======================================*/
/*-------------------------------------
	0. footer Large css
-------------------------------------*/
.footer-large .footer-bottom {
  padding: 20px 0px;
  color: #787878;
  font-size: 13px;
}

/*-------------------------------------
	1. footer fixed on bottom css
-------------------------------------*/
/*1.1 footer fixed with fullscreen view*/
.footer-fixed .site-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  border: none;
  z-index: 0;
}
.footer-fixed .page-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
}
@media only screen and (max-width: 1200px) {
  .footer-fixed .site-footer {
    position: static;
  }
  .footer-fixed .page-wraper {
    padding-bottom: 0 !important;
  }
}

/*1.2 footer fixed with boxed view*/

.site-footer .widget > .widget-title {
  text-transform: uppercase;
}
.site-footer p,
.site-footer li {
  line-height: 20px;
}

.site-footer {
  background-position: center;
  background-size: cover;
  border-top: 1px solid #dadada;
}
.site-footer p {
  line-height: 20px;
}

.site-footer .widget ul {
  list-style: none;
  margin-top: 5px;
}

.footer-dark .footer-bottom {
  padding: 100px 0px;
  color: #b7b7b7;
  font-size: 13px;
}
.footer-dark .footer-bottom .copyrights-text {
  color: #b7b7b7;
}
.footer-dark .footer-bottom .copyrights-nav {
  list-style: none;
  display: inline-block;
  margin-bottom: 0px;
}
.footer-dark .footer-bottom .copyrights-nav li {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}
.footer-dark .footer-bottom .copyrights-nav li:first-child {
  margin-left: 0;
}
.footer-dark .footer-bottom .copyrights-nav li:last-child {
  margin-right: 0;
}
.footer-dark .footer-bottom .copyrights-nav li:last-child:after {
  display: none;
}
.footer-dark .footer-bottom .copyrights-nav li:after {
  content: '/';
  position: relative;
  right: -8px;
}
.footer-dark .footer-bottom .copyrights-nav li a {
  color: #b7b7b7;
}
.footer-dark .footer-bottom .copyrights-nav li a:hover,
.footer-dark .footer-bottom .copyrights-nav li a:active,
.footer-dark .footer-bottom .copyrights-nav li a:focus,
.footer-dark .footer-bottom .copyrights-nav li.active a {
  color: #fff;
}

.footer-bottom {
  padding: 100px 0px;
  color: #787878;
  font-size: 13px;
}
.footer-bottom .copyrights-text {
  color: #000;
}
.footer-bottom .copyrights-nav {
  list-style: none;
  display: inline-block;
}
.footer-bottom .copyrights-nav li {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}
.footer-bottom .copyrights-nav li:first-child {
  margin-left: 0;
}
.footer-bottom .copyrights-nav li:last-child {
  margin-right: 0;
}
.footer-bottom .copyrights-nav li:last-child:after {
  display: none;
}
.footer-bottom .copyrights-nav li:after {
  content: '/';
  position: relative;
  right: -8px;
}
.footer-bottom .copyrights-nav li a {
  color: #000;
}
.footer-bottom .copyrights-nav li a:hover,
.footer-bottom .copyrights-nav li a:active,
.footer-bottom .copyrights-nav li a:focus,
.footer-bottom .copyrights-nav li.active a {
  color: #333333;
}

@media only screen and (max-width: 991px) {
  .site-footer {
    padding: 0px 0px;
  }
  .copyright-block {
    padding-top: 5px;
  }
}
/*-------------------------------------
	2. Footer dark version css
-------------------------------------*/
.footer-dark.site-footer {
  border-top: 1px solid #212121;
}

.footer-dark .footer-top {
  background-color: #000;
}
.footer-dark .footer-bottom {
  background-color: #000;
}
.footer-dark .footer-top h1,
.footer-dark .footer-top h2,
.footer-dark .footer-top h3,
.footer-dark .footer-top h4,
.footer-dark .footer-top h5,
.footer-dark .footer-top h6,
.footer-dark .footer-top h1 a,
.footer-dark .footer-top h2 a,
.footer-dark .footer-top h3 a,
.footer-dark .footer-top h4 a,
.footer-dark .footer-top h5 a,
.footer-dark .footer-top h6 a {
  color: #fff;
}
.footer-dark .footer-top .widget-title::before {
  border-color: #fff;
}
.footer-dark .footer-top p,
.footer-dark .footer-top strong,
.footer-dark .footer-top b,
.footer-dark .footer-top {
  color: #999;
}

.footer-dark .footer-top a:active,
.footer-dark .footer-top a:focus,
.footer-dark .footer-top a:hover {
  color: #ffbc13;
}
.footer-dark .footer-top p a {
  color: #3396d1;
}
.footer-dark .footer-top .widget_newsletter .form-control {
  background-color: #0e0e0e;
  border-color: #2d2d2d;
}

.footer-light .footer-top {
  background-color: #eee;
}
.footer-light .footer-bottom {
  background-color: #f6f7f8;
}

.footer-top {
  padding: 50px 0px 10px 0px;
}

.footer-wide .container {
  width: 100%;
  padding: 0px 80px;
}

@media only screen and (max-width: 991px) {
  .footer-wide .container {
    padding: 0px 15px;
  }
}

/*-------------------------------------
	5. widget Getintuch
-------------------------------------*/

.footer-bottom ul {
  margin: 0;
}
.wt-footer-bot-left,
.wt-footer-bot-right,
.wt-footer-bot-center {
  padding-left: 15px;
  padding-right: 15px;
}
.wt-footer-bot-left {
  float: left;
}
.wt-footer-bot-right {
  float: right;
}
.wt-footer-bot-center {
  float: right;
}

@media only screen and (max-width: 991px) {
  footer .container {
    width: 100%;
  }
  .col-md-3.col-sm-6.footer-col-4:nth-child(3) {
    clear: both;
  }

  .wt-footer-bot-left,
  .wt-footer-bot-right {
    float: none;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .wt-footer-bot-right .copyrights-nav {
    float: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer-clear {
    display: block !important;
    clear: both;
  }
  #footer .footer-4-col {
    width: 100%;
  }
  .footer-bottom [class*='clo-'] {
    width: 100%;
  }
}

/*-------------------------------------
	6. scroll top btn css
-------------------------------------*/
button.scroltop {
  height: 40px;
  width: 40px;
  background: #000;
  border: none;
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: none;
  text-align: center;
  z-index: 999;
  color: #fff;
}
button.scroltop span {
  display: block;
  font-size: 24px;
  line-height: 24px;
}

.string {
  position: relative;
}
#btn-vibrate {
  animation: vibrate 2s linear 0s infinite;
  -moz-animation: vibrate 2s linear 0s infinite;
  /* Firefox */
  -webkit-animation: vibrate 2s linear 0s infinite;
  /* Safari and Chrome */
  -o-animation: vibrate 2s linear 0s infinite;
  /* Opera */
}
@keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}
/* Firefox */
@-moz-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}
/* Safari and Chrome */
@-webkit-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}
/* Opera */
@-o-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}

@media only screen and (max-width: 480px) {
  button.scroltop {
    font-size: 7px;
    height: 30px;
    width: 30px;
    line-height: 16px;
  }
  button.scroltop span {
    font-size: 10px;
    line-height: 10px;
  }
}

/*======================================
23. PAGE-CONTENT
======================================*/
#bg {
  background-attachment: fixed;
  background-size: cover;
}

.page-wraper {
  background: #fff;
}
.page-content {
  position: relative;
}
.section-full {
  position: relative;
  padding: 4rem 7%;
}
/*-------------------------------------
	1. Section Head Part 
-------------------------------------*/
.section-head {
  margin-bottom: 40px;
}
.section-head.no-margin {
  margin-bottom: 0;
}

.section-head h1,
.section-head h2,
.section-head h3 {
  margin-top: 0;
}
.title-small {
  display: block;
  color: #7a959d;
  margin-bottom: 15px;
}
.section-head p {
  padding-top: 10px;
  font-size: 14px;
}
.section-head p:last-child {
  margin-bottom: 0;
}
.text-center.section-head p {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.text-white {
  color: #fff;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white .title-small {
  color: #fff;
}

/*-------------------------------------
	2. Section Content Part 
-------------------------------------*/

@media only screen and (max-width: 1024px) {
  .section-full {
    background-attachment: scroll !important;
  }
}

/*======================================
24. AFTER-LOGIN-CONTENT
======================================*/

/*-------------------------------------
	2. Contact us page 
-------------------------------------*/
ul.contact-info li {
  padding: 0 0 15px 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  line-height: 20px;
}

ul.contact-info li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

ul.contact-info li h4 ul.contact-info li h5,
ul.contact-info li h6 {
  margin: 0 0 10px;
}
ul.contact-info li p {
  line-height: 20px;
  margin: 0;
}
ul.contact-info li .fa {
  position: absolute;
  left: 0;
  top: 2px;
  color: #3396d1;
  font-size: 16px;
}

/*======================================
25. THEMEFOREST REQUIRED CLASSES
======================================*/
.sticky {
  clear: both;
}
.gallery-caption {
  clear: both;
}
.bypostauthor {
  clear: both;
}

/*-------------------------------------
	1. Text meant only for screen readers
-------------------------------------*/
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/*======================================
27. BOX MAX-WIDTH css (  )
======================================*/
.max-w100 {
  max-width: 100px;
}
.max-w200 {
  max-width: 200px;
}
.max-w300 {
  max-width: 300px;
}
.max-w400 {
  max-width: 400px;
}
.max-w500 {
  max-width: 500px;
}
.max-w600 {
  max-width: 600px;
}
.max-w700 {
  max-width: 700px;
}
.max-w800 {
  max-width: 800px;
}
.max-w900 {
  max-width: 900px;
}
.max-w1000 {
  max-width: 1000px;
}

/*======================================
28. SHOP & PRODUCT DETAIL (Woocommerce)
======================================*/
/*-------------------------------------
	1. Product Box 
-------------------------------------*/
.wt-product-box .price {
  margin-bottom: 10px;
}
.wt-product-box .price del {
  font-size: 16px;
  color: #999999;
  margin: 4px 4px;
  font-weight: 600;
}
.wt-product-box .price ins {
  font-size: 16px;
  color: #111111;
  text-decoration: none;
  margin: 4px 4px;
  font-weight: 600;
}
.wt-product-box .rating-bx {
  padding-top: 10px;
  color: #ffa000;
}
.comment-reply-title {
  margin-top: 0;
}
.comment-form [class*='comment-form'] {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .comment-form [class*='comment-form'] {
    width: 100%;
  }
}
.comment-form [class*='-form-author'],
.comment-form [class*='-form-email'] {
  float: left;
}
.comment-form [class*='-form-comment'] {
  width: 100%;
}
.comment-form label {
  display: block;
}
.star-Rating-input i {
  font-size: 18px;
}
.star-Rating-input .fa-star {
  color: #ffbc13;
}
.star-Rating-input i:hover {
  color: #ffbc13;
}
.comment-form [class*='comment-form'] input,
.comment-form [class*='comment-form'] textarea {
  border: 1px solid #ccc;
  padding: 8px 10px;
  width: 100%;
}
.comment-form {
  margin: 0 -15px;
}
.comment-form [class*='comment-form'],
.comment-form .form-submit {
  margin-bottom: 20px;
  padding: 0 15px;
}
.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
  background: #037a85;
}
/*======================================
29. content show on image box () 
======================================*/
@media only screen and (max-width: 768px) {
  .wt-media > img,
  .wt-media a > img {
    width: 100%;
  }
}

/*======================================
30. Customize  Grouping css 
======================================*/

/*-------------------------------------
	1.set-radius 
-------------------------------------*/
.btn,
.panel,
.form-control,
.img-thumbnail,
.panel-head ing,
.dropdown-menu,
.panel-group .panel,
.nav-tabs > li > a,
.modal-content,
.navbar-toggle,
.nav-pills > li > a,
.pager li > a,
.pager li > span,
.well,
.alert,
.list-group-item:first-child,
.list-group-item:last-child,
.input-group-addon,
.btn-group-divides
  > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-divides > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child),
.wt-booking-form .wt-extra-services li .wt-icon-box-lg,
.wt-booking-form .wt-extra-services li.active .wt-icon-box-lg:after,
.searchform input[type='text'],
.searchform input[type='submit'],
ol.comment-list li.comment .comment-body {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

/*------------------------------------- 
	2. transition 
-------------------------------------*/
a,
.btn,
.form-control,
.overlay-bx,
.overlay-icon li a i,
.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs,
.share-social-bx li,
.indicator,
.profile-menu ul li,
.staff-member .member-done,
.how-wt-work .wt-box .shadow-bx img,
.how-wt-work .wt-box .step-no-bx,
.wt-info-has,
.wt-we-find .wt-box,
.navbar-toggle,
.logo-header,
.header-nav .nav > li > a,
.extra-nav,
.acod-head .indicator .fa {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.animate-slow {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.animate-mid {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.animate-fast {
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.8s ease-out;
  -o-transition: all 0.8s ease-out;
  -ms-transition: all 0.8s ease-out;
  transition: all 0.8s ease-out;
}

/*------------------------------------- 
	3. float-clearfix 
-------------------------------------*/
.clearfix::after,
.filter-wrap::after,
.filter-wrap::after,
.wt-icon-box-wraper::after,
ol.commentlist li::after {
  content: '';
  display: table;
  clear: both;
}

/*======================================
32. Inner pages 
======================================*/

/*------------------------------------- 
	2. Out Team
-------------------------------------*/

/*2.6. Out Team six*/
.team-outer {
  margin-top: 198px;
}
@media only screen and (max-width: 767px) {
  .team-outer {
    margin-top: 0px;
  }
}

.wt-team-six .wt-team-info {
  position: relative;
}

.wt-team-six .wt-team-info:after,
.wt-team-six .wt-team-info:before {
  content: '';
  position: absolute;
  left: -4px;
  bottom: -4px;
  background-color: #000;
}

.wt-team-six.large-pic .wt-team-info:after,
.wt-team-six.large-pic .wt-team-info:before {
  visibility: hidden;
}

.wt-team-six .wt-team-info:after {
  width: 4px;
  height: 40px;
}
.wt-team-six .wt-team-info:before {
  width: 40px;
  height: 4px;
}
.wt-team-six:hover .overlay-bx {
  opacity: 1;
}

/*------------------------------------- 
	4. Product block
-------------------------------------*/
.product-block p {
  margin-bottom: 0px;
}

/*======================================
33. Background Effects
======================================*/
.bg-center {
  background-position: center;
}

.bg-top-left {
  background-position: top left;
}
.bg-top-right {
  background-position: top right;
}
.bg-top-center {
  background-position: top center;
}

.bg-bottom-left {
  background-position: bottom left;
}
.bg-bottom-right {
  background-position: bottom right;
}
.bg-bottom-center {
  background-position: bottom center;
}

.bg-left-center {
  background-position: left;
}
.bg-right-center {
  background-position: left;
}

.bg-auto {
  background-size: auto;
}
.bg-contain {
  background-size: contain;
}
.bg-cover {
  background-size: cover;
}
.bg-full-width {
  background-size: 100% auto;
}
.bg-full-height {
  background-size: auto 100%;
}

.bg-repeat {
  background-repeat: repeat;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-repeat-x {
  background-repeat: repeat-x;
}
.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-fixed {
  background-attachment: fixed;
}
.bg-scroll {
  background-attachment: scroll;
}

/*======================================
34. Home Page
======================================*/

.m-carousel-1 {
  position: relative;
}
.m-carousel-1:after {
  content: '';
  position: absolute;
  top: 70px;
  left: -70px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 30px solid rgba(0, 0, 0, 0.1);
}

.m-carousel-1 .owl-carousel .owl-dots {
  position: absolute;
  right: 20px;
  margin-top: 0px;
  top: 50%;
  width: 0px;
  transform: translateX(-50%) translateY(-50%);
}
.m-carousel-1 .owl-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 3px;
  border: none;
  float: right;
  clear: both;
  position: relative;
}
.m-carousel-1 .owl-carousel .owl-dots .owl-dot span:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 3px;
  background-color: #000;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.m-carousel-1 .owl-carousel .owl-dots .owl-dot.active span:after {
  width: 50px;
}
@media only screen and (max-width: 991px) {
  .m-carousel-1 {
    margin-bottom: 100px;
    margin-left: 70px;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .m-carousel-1 {
    margin-top: 40px;
  }
  .m-carousel-1::after {
    left: -70px;
    border: 20px solid rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width: 420px) {
  .m-carousel-1 {
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .m-carousel-1:after {
    display: none;
  }
}

.hilite-title {
  border: 0px;
}
.hilite-title strong {
  display: block;
  font-size: 80px;
  line-height: 80px;
  font-weight: bold;
  color: rgba(191, 191, 191, 0.3);
}

.hilite-title span {
  display: block;
  font-size: 24px;
  line-height: 24px;
  font-family: 'Roboto Condensed', sans-serif;
}

.hilite-title.bdr-left {
  border-left: 10px;
  border-style: solid;
}
.hilite-title.bdr-right {
  border-right: 10px #ffc905;
  border-style: solid;
}

.m-carousel-2 {
  margin-right: 130px;
  margin-bottom: 50px;
  position: relative;
}
.m-carousel-2 .home-carousel-2 {
  border: 15px solid #fff;
  border-bottom: 0px;
  border-left: 0px;
}
.m-carousel-2 .home-carousel-2 .owl-nav {
  margin: 0px;
  position: absolute;
  bottom: -30px;
  left: 30px;
}

.m-carousel-2 .home-carousel-2 .owl-nav .owl-prev {
  left: -30px;
}
.m-carousel-2 .home-carousel-2 .owl-nav .owl-next {
  right: -44px;
}

.carousel-bg-img {
  position: absolute;
  right: -65px;
  top: -50px;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .m-carousel-2 {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .m-carousel-2 {
    margin-right: 65px;
  }
}
@media only screen and (max-width: 620px) {
  .m-carousel-2 .home-carousel-2 {
    border: none;
  }
  .carousel-bg-img {
    display: none;
  }
  .m-carousel-2 {
    margin-right: 0px;
    margin-top: 0px;
  }
  .hilite-title strong {
    font-size: 46px;
    line-height: 46px;
  }
  .hilite-title span {
    font-size: 18px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 460px) {
  .m-carousel-2 .carousel-hover .carousel-line h2 {
    font-size: 18px;
  }
  .m-carousel-2 .carousel-hover .carousel-line p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .m-carousel-2 .wt-carousel-info {
    width: 100%;
    padding: 25px;
  }
}

/*======================================
35. Carousel overlay content
======================================*/
.wt-carousel-block {
  position: relative;
}

.wt-carousel-info {
  position: absolute;
  bottom: 0px;
  z-index: 2;
}

.carousel-line {
  position: relative;
}

.carousel-line:after,
.carousel-line:before {
  position: absolute;
  content: '';
  background-color: #fff;
}
.carousel-line:after {
  width: 100px;
  height: 6px;
  left: -40px;
  bottom: -20px;
}
.carousel-line:before {
  width: 6px;
  height: 100px;
  left: -20px;
  bottom: -40px;
}
.carousel-hover .owl-item .gradi-black:before {
  opacity: 0;
}
.carousel-hover .owl-item.active .gradi-black:before {
  opacity: 1;
  -webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  -ms-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
}

.carousel-hover .wt-carousel-info {
  bottom: -300px;
  opacity: 0px;
}
.carousel-hover .owl-item.active .wt-carousel-info {
  bottom: 0px;
  opacity: 1px;
  -webkit-transition: all 1.5s linear;
  -moz-transition: all 1.5s linear;
  -ms-transition: all 1.5s linear;
  -o-transition: all 11.5s linear;
  transition: all 1.5s linear;
}

@media only screen and (max-width: 460px) {
  .carousel-line::after {
    width: 80px;
    height: 4px;
    left: -17px;
    bottom: -10px;
  }
  .carousel-line::before {
    width: 4px;
    height: 80px;
    left: -12px;
    bottom: -16px;
  }
}
/*===========================
36. Overlay Box 2
===========================*/
.overlay-bx-2 {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #000;
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.overlay-bx-2:hover {
  opacity: 0.9;
  bottom: 0px;
}
/*===========================
37. line-amiation-block
===========================*/
.line-amiation {
  position: relative;
  width: 100%;
  height: 100%;
}
.line-amiation:after,
.line-amiation:before {
  content: '';
  position: absolute;
  background-color: #fff;
  left: 20px;
  top: 20px;
  -webkit-transition: all 0.7s linear;
  -moz-transition: all 0.7s linear;
  -ms-transition: all 0.7s linear;
  -o-transition: all 0.7s linear;
  transition: all 0.7s linear;
  opacity: 0;
}
.line-amiation:after {
  width: 0%;
  height: 1px;
}
.line-amiation:before {
  width: 1px;
  height: 0px;
}
.line-amiation:hover:after {
  width: 100%;
  opacity: 1;
  left: -20px;
}
.line-amiation:hover:before {
  height: 100%;
  top: -20px;
  opacity: 1;
}

.line-amiation a.v-button {
  color: #fff;
  border-left: 2px solid;
  display: block;
  position: absolute;
  bottom: 90px;
  right: -40px;
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media only screen and (max-width: 420px) {
  .line-amiation a.v-button {
    bottom: 70px;
  }
}
/*===========================
38. latest-blog-dark
===========================*/
.latest-blog-dark-outer {
  height: 250px;
}
.latest-blog-dark {
  position: absolute;
  left: -20px;
  bottom: 20px;
  width: 260px;
  min-height: 120px;
  background-color: #000;
  padding-left: 40px;
}
.latest-blog-dark .v-date {
  position: absolute;
  left: -25px;
  top: 50px;
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
/*latest-blog-dark2*/
/*=========================*/
.latest-blog-dark-outer2 {
  height: 530px;
}
.latest-blog-square {
  position: absolute;
  right: -60px;
  bottom: 60px;
  width: 400px;
  min-height: 400px;
  padding: 25px 70px 25px 25px;
  border: 15px solid #000;
}
.latest-blog-square .v-date {
  position: absolute;
  left: -90px;
  top: 50px;
  font-size: 16px;
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media only screen and (max-width: 1024px) {
  .latest-blog-square {
    right: 15px;
    width: 80%;
  }
  .latest-blog-dark {
    left: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .latest-blog-square {
    padding: 15px;
  }
}

/*=========================*/
.some-facts {
  position: relative;
  padding: 60px 30px 60px 50px;
  margin-bottom: 40px;
  border: 15px solid #fff;
  border-right: 0px;
}

.some-facts:after,
.some-facts:before {
  content: '';
  position: absolute;
  background-color: #fff;
  right: -15px;
  width: 15px;
  height: 140px;
}
.some-facts:after {
  bottom: -15px;
}
.some-facts:before {
  top: -15px;
}
.some-facts-counter {
  padding-top: 130px;
}

@media only screen and (max-width: 991px) {
  .some-facts {
    margin-right: 15px;
  }
  .some-facts-counter {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .some-facts:after,
  .some-facts:before {
    height: 60px;
  }
}

@media only screen and (max-width: 480px) {
  .some-facts {
    padding: 20px;
  }
  .some-facts span {
    font-size: 24px;
    line-height: inherit;
  }
  .some-facts h2 {
    font-size: 24px;
    line-height: inherit;
  }

  .some-facts p {
    font-size: 14px;
    line-height: inherit;
  }
}
/*=========================*/

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav {
  position: absolute;
  margin-top: 0px;
  top: -10px;
  left: -80px;
}
.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-prev {
  left: 40px;
  position: relative;
  background-color: #1a1a1a;
  margin: 0px;
  border: none;
}
.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-next {
  left: 5px;
  position: relative;
  top: 40px;
  background-color: #1a1a1a;
  margin: 0px;
  border: none;
}
@media only screen and (max-width: 800px) {
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav {
    left: auto;
    right: 5px;
    top: auto;
    bottom: -47px;
  }
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-prev {
    left: 0px;
  }
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-next {
    left: 5px;
    top: 0px;
  }
}
/*=========================
39. background Square block
=========================*/
/*shape1*/
.square_shape1,
.square_shape2,
.square_shape3,
.square_shape4 {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.square_shape1:after,
.square_shape2:before,
.square_shape3:before,
.square_shape4:after {
  width: 800px;
  height: 800px;
  position: absolute;
  content: '';
  border: 40px solid rgba(191, 191, 191, 0.1);

  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}
/*shape1*/
.square_shape1:after {
  left: -700px;
  top: 50px;
}
/*shape2*/
.square_shape2:before {
  right: -700px;
  bottom: -340px;
}
/*shape3*/
.square_shape3:before {
  right: -700px;
  bottom: -300px;
}
/*shape4*/
.square_shape4:after {
  left: -820px;
  top: -260px;
}

/*=========================
40. About us
=========================*/
.m-about {
  position: relative;
  z-index: 2;
  margin-bottom: 50px;
}
.m-about:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 10px solid #000;
  left: -50px;
  bottom: -50px;
  z-index: -1;
}
.about-us-carousel.owl-carousel .owl-nav {
  margin-top: 0px;
  position: absolute;
  right: -15px;
  bottom: 15px;
}
.about-us-carousel.owl-carousel .owl-nav .owl-prev,
.about-us-carousel.owl-carousel .owl-nav .owl-next {
  display: block;
  margin: 0px;
}
@media only screen and (max-width: 991px) {
  .m-about {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .m-about {
    margin: 0px 0px 0px 50px;
  }
  .m-about-containt {
    margin-top: 60px;
  }
  .about-us-carousel.owl-carousel .owl-nav {
    right: 15px;
  }
}
/*======================================
41. Our Services
======================================*/
.circle-block-outer .wt-icon-box-wraper {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  border: 2px solid #fff;
}
.circle-block-outer .wt-icon-box-wraper:hover {
  background-color: #fff;
  cursor: pointer;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.circle-block-outer .wt-icon-box-wraper:hover .icon-content h4,
.circle-block-outer .wt-icon-box-wraper:hover .icon-content p {
  color: #000;
}
@media only screen and (max-width: 991px) {
  .circle-content-pic {
    text-align: center;
  }
}

/*======================================
42. Contact Us
======================================*/
.contact-one {
  border: 6px solid #000;
  position: relative;
  max-width: 945px;
}
.contact-one .form-control {
  border: 0px;
  border-bottom: 1px solid #000;
}
.contact-one .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}
.contact-one .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}
.contact-one .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}
.contact-one .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}
.contact-info {
  position: absolute;
  right: -200px;
  top: 30px;
  width: 310px;
}
.contact-info .icon-content a {
  display: block;
  text-decoration: none;
  color: #fff;
}

.contact-info .icon-content p {
  word-break: normal;
}

@media only screen and (max-width: 1200px) {
  .contact-one {
    max-width: 745px;
  }
}
@media only screen and (max-width: 991px) {
  .contact-one {
    padding-right: 375px;
  }
  .contact-info {
    right: 40px;
    width: 310px;
    top: 21px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-one {
    padding-right: 40px;
  }
  .contact-info {
    right: 0px;
    width: 100%;
    top: 0px;
    position: inherit;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-one {
    padding: 15px;
  }
}

/*======================================
43. Work carousal
======================================*/
/*====preview inner pages ====*/
.work-carousel .owl-stage {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media only screen and (max-width: 853px) {
  .work-carousel .owl-stage {
    padding: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .work-carousel .owl-stage {
    margin-bottom: 30px;
  }
}
.work-carousel .owl-item {
  background-color: #fff;
}
.work-carousel .owl-item > .item {
  opacity: 0.6;
}
.work-carousel .owl-item.active.center > .item {
  opacity: 1;
}
.work-carousel .owl-item.active.center {
  opacity: 1;
  z-index: 1;
  -webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  -ms-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;

  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.work-carousel .owl-item.active.center {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 1200px) {
  .work-carousel .owl-item > .item h2 {
    margin-bottom: 10px;
  }
  .work-carousel .owl-item > .item h2 a {
    font-size: 24px;
    line-height: 24px;
  }
  .work-carousel .line-amiation {
    padding: 10px 40px;
  }
}
@media only screen and (max-width: 991px) {
  .project-detail-containt.bg-black {
    background-color: transparent;
  }
}
@media only screen and (max-width: 767px) {
  .project-detail-containt .font-34 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 854px) {
  .work-carousel .owl-item.active.center {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@media only screen and (max-width: 768px) {
  .latest_project-outer {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .latest_project-outer {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .work-carousel .owl-item p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 360px) {
  .work-carousel .owl-item > .item h2 a {
    font-size: 14px;
    line-height: 14px;
  }
}
/*======================================
44. Work Masonry
======================================*/
.work-masonry .line-amiation h2 {
  margin-top: 0px;
}
@media only screen and (max-width: 960px) {
  .work-masonry .line-amiation h2 {
    margin-bottom: 10px;
    line-height: 24px;
  }
  .work-masonry .line-amiation h2 a {
    font-size: 18px;
    line-height: inherit;
  }
}
@media only screen and (max-width: 768px) {
  .work-masonry .line-amiation h2 {
    line-height: normal;
  }
  .work-masonry .line-amiation h2 a {
    font-size: 24px;
    line-height: inherit;
  }
}
@media only screen and (max-width: 540px) {
  .work-masonry .line-amiation h2 {
    line-height: 24px;
  }
  .work-masonry .line-amiation h2 a {
    font-size: 18px;
    line-height: inherit;
  }
}
@media only screen and (max-width: 420px) {
  .work-masonry .line-amiation h2 {
    line-height: 18px;
  }
  .work-masonry .line-amiation h2 a {
    font-size: 14px;
    line-height: inherit;
  }
}
@media only screen and (max-width: 360px) {
  .work-masonry .line-amiation h2 {
    line-height: 16px;
  }
  .work-masonry .line-amiation h2 a {
    font-size: 12px;
    line-height: inherit;
  }
  .line-amiation a.v-button {
    bottom: 60px;
    right: -50px;
  }
}

/*=========================
45. our-story
=========================*/
.our-story .our-story-detail {
  padding: 15px 30px;
  margin: 62px 0px;
}
.our-story .our-story-detail p {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}
.our-story {
  background-image: url(./images/background/ptn-1.png);
}
.our-story .row {
  margin-top: 62px;
  margin-bottom: 62px;
  position: relative;
}
.our-story .row:nth-child(odd) {
  text-align: left;
}
.our-story .row:nth-child(even) {
  text-align: right;
}
.our-story .row:nth-child(odd) .our-story-detail {
  position: absolute;
  left: -50px;
  z-index: 5;
  background-color: #fff;
  right: 15px;
}
.our-story .row:nth-child(even) .our-story-detail {
  position: absolute;
  right: -50px;
  z-index: 5;
  background-color: #000;
  left: 15px;
}
.our-story .row:nth-child(even) .our-story-detail h3,
.our-story .row:nth-child(even) .our-story-detail h2,
.our-story .row:nth-child(even) .our-story-detail p {
  color: #fff;
}
.our-story .row:last-child {
  margin-bottom: 0px;
}

@media only screen and (max-width: 1200px) {
  .our-story .our-story-detail {
    padding: 15px 30px;
    margin: 15px 0px;
  }
}
@media only screen and (max-width: 991px) {
  .our-story .row:nth-child(odd) .our-story-detail {
    position: inherit;
    left: 0px;
  }
  .our-story .row:nth-child(even) .our-story-detail {
    position: inherit;
    right: 0px;
    left: 0px;
  }
  .our-story .row:nth-child(even) {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .our-story .row {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .wt-box.our-story-detail h2 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .wt-box.our-story-detail h3 {
    font-size: 16px;
    line-height: 22px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .our-story .our-story-detail {
    padding: 20px;
  }
  .our-story .our-story-detail p {
    margin-bottom: 0px;
  }
}

/*=========================================================

=============================================================*/

/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel-filter .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel-filter .owl-animated-in {
  z-index: 0;
}
.owl-carousel-filter .owl-animated-out {
  z-index: 1;
}
.owl-carousel-filter .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel-filter {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel-filter .owl-stage {
  position: relative;
}
.owl-carousel-filter .owl-stage:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel-filter .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.owl-carousel-filter .owl-controls .owl-nav .owl-prev,
.owl-carousel-filter .owl-controls .owl-nav .owl-next,
.owl-carousel-filter .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel-filter.owl-loaded {
  display: block;
}
.owl-carousel-filter.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel-filter.owl-hidden {
  opacity: 0;
}
.owl-carousel-filter .owl-refresh .owl-item {
  display: none;
}
.owl-carousel-filter .owl-item {
  position: relative;
  min-height: 1px;
  float: left;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel-filter .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.owl-carousel-filter.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel-filter .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel-filter.owl-rtl {
  direction: rtl;
}
.owl-carousel-filter.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel-filter {
  display: block;
}

/*===============================*/
.btn-filter-wrap {
  list-style: none;
  margin: 64px 0 0;
  float: right;
}
.btn-filter-wrap li {
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  padding: 0 20px;
  cursor: pointer;
}
.btn-filter-wrap li:last-child {
  padding-right: 0;
}
.btn-filter-wrap li.btn-active {
  color: #d5dd02;
}
.btn-filter-wrap li:after {
  content: '/';
  position: absolute;
  left: -2px;
  top: 4px;
  font-size: 14px;
  opacity: 0.2;
  color: #000;
}
.btn-filter-wrap li:first-child:after {
  display: none;
}

.owl-carousel-filter .owl-item {
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.owl-carousel-filter .owl-item.active {
  opacity: 1;
}
.owl-carousel-filter .owl-item > .owl-item {
  opacity: 1;
}

/*===============
	PARTICLE CANVAS
=================*/
.particles-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

#particles-js {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #b61924;
  background-image: url('');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
#particles-js2 {
  position: absolute;
  right: 0px;
  width: 50%;
  height: 100%;
  background-color: #888;
  background-image: url('');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

/*=======================
	after before slider
========================*/
.rs-addon-beforeafter-btn i {
  color: #000;
}

/* cursor blink animation*/

.typewrite > .wrap:after {
  content: '|';
  -webkit-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
  position: relative;
  right: 10px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*=================================================================================================================================
New Menu Style for mobile device (august/2021)
==================================================================================================================================*/

@media only screen and (max-width: 991px) {
  .header-nav .nav > li > a {
    padding: 10px 15px;
  }
  /*Header Style 1*/
  .header-style-1 .header-nav .nav {
    float: none;
    margin: 0px;
    width: 100%;
    display: block;
    height: 100%;
  }
  .header-style-1 .header-nav .nav > li > a {
    height: 50px;
    line-height: 30px;
  }
  .header-style-1 .navbar-collapse.collapse {
    display: inherit !important;
  }
  .header-style-1 .navbar-collapse.collapse.show {
    display: inherit !important;
  }
  .header-style-1 .header-nav {
    margin: 0px;
  }
  .header-style-1 .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none;
  }

  .header-style-1.mobile-responsive-navigation .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }
  .header-style-1.mobile-responsive-navigation
    .header-nav
    .nav
    > li:hover
    > .sub-menu {
    transition: none;
  }
  .header-style-1.mobile-responsive-navigation
    .header-nav
    .nav
    > li
    .sub-menu
    li:hover
    > .sub-menu {
    transition: none;
  }
  .header-style-1.mobile-responsive-navigation.active .header-nav {
    left: 0%;
  }
  .header-style-1.mobile-responsive-navigation .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }
  .header-style-1.mobile-responsive-navigation #mobile-side-drawer {
    position: relative;
    z-index: 1;
  }
  .header-style-1.mobile-responsive-navigation.active .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }
  .header-style-1.mobile-responsive-navigation.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }
  .header-style-1.mobile-responsive-navigation .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }
  .header-style-1.mobile-responsive-navigation.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }
  .header-style-1.mobile-responsive-navigation .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
  .header-style-1.mobile-responsive-navigation .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }

  /*Header transparent*/
  .nav-transparent .header-nav .nav {
    float: none;
    margin: 0px;
    width: 100%;
    display: block;
    height: 100%;
  }
  .nav-transparent .header-nav .nav > li > a {
    height: 50px;
  }
  .nav-transparent .navbar-collapse.collapse {
    display: inherit !important;
  }
  .nav-transparent .navbar-collapse.collapse.show {
    display: inherit !important;
  }
  .nav-transparent .header-nav {
    margin: 0px;
  }
  .nav-transparent .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none;
  }

  .nav-transparent.mobile-responsive-navigation .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }
  .nav-transparent.mobile-responsive-navigation
    .header-nav
    .nav
    > li:hover
    > .sub-menu {
    transition: none;
  }
  .nav-transparent.mobile-responsive-navigation
    .header-nav
    .nav
    > li
    .sub-menu
    li:hover
    > .sub-menu {
    transition: none;
  }
  .nav-transparent.mobile-responsive-navigation.active .header-nav {
    left: 0%;
  }
  .nav-transparent.mobile-responsive-navigation .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }
  .nav-transparent.mobile-responsive-navigation #mobile-side-drawer {
    position: relative;
    z-index: 1;
  }
  .nav-transparent.mobile-responsive-navigation.active
    .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }
  .nav-transparent.mobile-responsive-navigation.active
    .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }
  .nav-transparent.mobile-responsive-navigation .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }
  .nav-transparent.mobile-responsive-navigation.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }
  .nav-transparent.mobile-responsive-navigation .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
  .nav-transparent.mobile-responsive-navigation .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
}

/*max-mid-container*/
.max-mid-container {
  max-width: 991px;
  margin-left: auto;
  margin-right: auto;
}
.tp-loader.spinner3 {
  display: none;
}
.wt-team-media .social-icons {
  margin-bottom: 0px;
}

@media only screen and (max-width: 1024px) {
  .btn-filter-wrap {
    float: left;
  }
  .btn-filter-wrap li {
    padding-left: 0px;
  }
  .btn-filter-wrap li:after {
    display: none;
  }
  .font-28 {
    font-size: 22px;
    line-height: 28px;
  }
  .section-full.p-t140 {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 991px) {
  .team-outer {
    margin-top: 90px;
    margin-bottom: 90px;
    padding-left: 0px;
  }
  .footer-bottom {
    padding: 40px 0px;
  }
  .footer-dark .footer-bottom {
    padding: 40px 0px;
  }
  .latest_project-outer {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    padding: 90px 15px 0px 15px;
  }
  .tm-whoweare-wrap {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    padding: 90px 0px 0px 0px;
  }
  .tm-whoweare-wrap .container {
    max-width: 750px;
    padding: 0px;
  }
  .tm-expert-wrap .wt-left-part.m-experts {
    max-width: 720px;
    padding: 90px 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .tm-expert-wrap .wt-right-part.team-outer {
    max-width: 720px;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .tm-expert-wrap2 .wt-left-part.m-experts {
    max-width: 720px;
    padding: 90px 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .tm-expert-wrap2 .wt-right-part.team-outer {
    max-width: 720px;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .tm-expert-wrap2 .wt-team-info {
    padding-bottom: 0px;
  }
  .tm-expert-wrap2 .wt-team-info ul {
    margin-bottom: 0px;
  }
  .m-carousel-2 {
    margin-bottom: 90px;
  }
  .tm-client-wrap .text-left {
    margin-bottom: 30px;
  }
  .counter.font-60 {
    font-size: 36px;
    line-height: 42px;
  }
  .counter + span.font-18 {
    font-size: 14px;
    line-height: 20px;
  }
  .table {
    margin-bottom: 0px;
  }
  .tm-service2-wrap .circle-block-outer .wt-icon-box-wraper.right {
    text-align: left;
  }
}

@media only screen and (max-width: 800px) {
  .tm-client-wrap {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 768px) {
  .tm-expert-wrap .wt-left-part.m-experts {
    padding-bottom: 0px;
  }
  .tm-expert-wrap2 .wt-left-part.m-experts {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .font-40 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .font-36 {
    font-size: 24px;
    line-height: 30px;
  }
  .font-30 {
    font-size: 20px;
    line-height: 26px;
  }
  .font-32 {
    font-size: 26px;
    line-height: 32px;
  }
  .wt-post-title.post-title .font-20 {
    font-size: 18px;
  }
  .button-lg {
    padding: 14px 15px;
    font-size: 16px;
  }
  .btn-half:hover em {
    width: 14%;
  }
  .section-full.p-tb100 {
    padding-bottom: 10px;
  }
  .section-head h1,
  .section-head h2,
  .section-head h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  .btn-filter-wrap {
    margin-top: 20px;
  }
  .wt-left-part.m-experts {
    padding-top: 40px;
  }
  .section-full.p-t140 {
    padding-top: 40px;
  }
  .team-outer {
    padding-top: 24px;
    margin: 0px;
    padding-bottom: 30px;
  }

  .latest-blog-dark h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .latest_project-outer {
    padding-top: 40px;
  }

  .some-facts {
    margin-bottom: 0px;
  }
  .counter.font-60 {
    font-size: 24px;
    line-height: 30px;
  }
  .news-masonry [class*='col-']:last-child .blog-post.blog-grid.date-style-1 {
    margin-bottom: 0px;
  }

  .tm-welcome-warp {
    padding-top: 40px;
  }
  .tm-expert-wrap .wt-left-part.m-experts {
    padding-top: 40px;
  }
  .tm-expert-wrap .wt-left-part.m-experts .wt-team-title {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .tm-expert-wrap .wt-left-part.m-experts .wt-team-title span {
    font-size: 16px;
  }
  .tm-expert-wrap .wt-left-part.m-experts p {
    font-size: 14px;
  }
  .tm-expert-wrap .wt-right-part.team-outer {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .tm-expert-wrap2 .wt-left-part.m-experts {
    padding-top: 40px;
  }
  .tm-expert-wrap2 .wt-left-part.m-experts .wt-team-title {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .tm-expert-wrap2 .wt-left-part.m-experts .wt-team-title span {
    font-size: 16px;
  }
  .tm-expert-wrap2 .wt-left-part.m-experts p {
    font-size: 14px;
  }
  .tm-expert-wrap2 .wt-right-part.team-outer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tm-client2-wrap {
    padding-top: 40px;
    padding-bottom: 25px;
  }
  .tm-shortcode-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tm-about2-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tm-shortcode-icon-4 [class*='col-']:last-child {
    margin-bottom: 0px;
  }
  .tm-blog-single-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tm-qt-img {
    margin-bottom: 20px;
  }
  .tm-work-wrap {
    padding-top: 40px;
    padding-bottom: 10px;
  }
  .tm-work-detail-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tm-news-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tm-service2-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tw-our-story-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tm-service2-wrap .circle-block-outer [class*='col-']:last-child {
    margin-bottom: 0px;
  }
  .tm-service2-wrap .circle-block-outer .wt-icon-box-wraper:last-child {
    margin-bottom: 0px;
  }
  .tm-client-wrap .text-left h2 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  .tm-facts-wrap {
    padding-bottom: 0px;
    padding-top: 40px;
  }
  .tm-facts-wrap .font-40 {
    font-size: 20px;
    line-height: 26px;
  }
  .tm-facts-wrap .font-50 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .tm-facts-wrap .font-18 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .tm-facts-wrap .some-facts {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .some-facts-counter [class*='col-xs-'] {
    width: 100%;
  }
  .tm-counter-shortcode [class*='col-xs-'] {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .font-40 {
    font-size: 22px;
    line-height: 28px;
  }
  .font-36 {
    font-size: 20px;
    line-height: 26px;
  }
  .font-32 {
    font-size: 20px;
    line-height: 26px;
  }
  span.font-30 {
    font-size: 18px;
    line-height: 24px;
  }
  .wt-team-six.large-pic h2 a.font-32 {
    font-size: 22px;
    line-height: 28px;
  }
  .font-22 {
    font-size: 18px;
    line-height: 24px;
  }
  blockquote {
    padding: 30px;
  }
  blockquote:before {
    position: inherit;
    left: auto;
    top: auto;
  }
}

/* *{border: 1px solid teal;} */

@media only screen and (max-width: 736px) {
  #welcome_wrapper .tp-caption.rev_row {
    padding: 0px !important;
  }
}
