.project-card {
  position: relative;
  width: 294px;
  min-width: 240px;
  max-height: 420px;
}
.project-card .card-bg-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.project-card img {
  width: 100%;
  height: auto;
  transform: scale(1);
  transform-origin: bottom center;
  transition: transform ease-in-out 0.5s;
}
.project-card .card-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
}
.project-card:hover img {
  transform: scale(1.35);
}
.project-card:hover .card-overlay {
  opacity: 1;
}
.card-overlay {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3rem;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity ease-in-out 0.5s;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.616011) 44.95%,
    rgba(255, 255, 255, 0.8) 100%
  );
}
.card-overlay a {
  color: #1e1e1e;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 2rem;
  border-top: 1px solid #1e1e1e;
  border-bottom: 1px solid #1e1e1e;
}
.card-overlay a .first-name {
  display: inline-block;
  background-color: #fcb900;
  line-height: 1.5rem;
  overflow: hidden;
}

@media screen and (max-width: 1080px) {
  .in-view img {
    transform: scale(1.35);
  }
  .in-view .card-overlay {
    opacity: 1;
  }
  .project-card:not(.in-view):hover img {
    transform: scale(1);
  }
  .project-card:not(.in-view):hover .card-overlay {
    opacity: 0;
  }
}
