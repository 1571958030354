.our-clients-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  background-color: #1c1d1d;
  min-height: 13.25rem;
  padding-right: 7%;
}
.our-clients-section * {
  margin: 0;
}
.our-clients-section .our-clients-logo {
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 13.25rem;
  min-width: 19rem;
  padding-left: 7%;
  padding-right: 2rem;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  overflow: hidden;
  white-space: nowrap;
}
.our-clients-section .our-clients-logo h2 {
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  text-transform: capitalize;
}
.our-clients-section .clients-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
}
.our-clients-section .clients-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 25%;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 1920px;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.our-clients-section .clients-container::-webkit-scrollbar {
  display: none;
}

.our-clients-section.expanded .clients-container {
  grid-auto-flow: unset;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  padding-block: 4rem;
}

.our-clients-section .clients-container .client-logo {
  scroll-snap-align: start;
  scroll-padding-inline: 1rem;
}
.our-clients-section .clients-container .client-logo img {
  aspect-ratio: 16/9;
  object-fit: cover;
}

.our-clients-section .our-clients-controller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.our-clients-section .our-clients-controller > button {
  background-color: transparent;
  border: 0;
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.expand-btn {
  position: absolute;
  bottom: 1px;
  inset-inline-end: 50%;
  transform: translate(50%, 100%);
  font-size: 1.125rem;
  padding: 0.25rem 2rem 0.75rem;
  border: none;
  outline: none;
  color: #fff;
  background-color: inherit;
  cursor: pointer;
  z-index: 10;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

@media screen and (max-width: 1020px) {
  .our-clients-section .clients-container {
    grid-auto-columns: 33%;
    justify-items: center;
  }

  .our-clients-section.expanded .clients-container {
    grid-auto-flow: unset;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}
@media screen and (max-width: 840px) {
  .our-clients-section {
    flex-direction: column;
    padding-left: 7%;
    padding-bottom: 2rem;
  }
  .our-clients-section .our-clients-logo {
    align-self: center;
  }

  .our-clients-section .clients-list {
    flex-direction: column-reverse;
    gap: 3rem;
  }
  .our-clients-section .our-clients-controller {
    flex-direction: row;
  }
}
