.hero-section .bg-container img {
  width: 100%;
}
.hero-section {
  position: relative;
  overflow: hidden;
}
.hero-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 33.28%,
    #ffffff 100%
  );
}
.hero-section .cloud-container {
  position: relative;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
}
.hero-section .cloud-container img {
  mix-blend-mode: screen;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  transition: transform ease-in-out 30s;
  transform: translateX(-100%);
}
.hero-section .cloud-container img:nth-child(2) {
  transform: translateX(100%);
}
.hero-section .bg-loaded img:nth-child(1) {
  transform: translateX(70%);
}
.hero-section .bg-loaded img:nth-child(2) {
  transform: translateX(-70%);
}
.hero-section .slogan {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
}
.hero-section .slogan h1 {
  font-weight: 300;
  font-size: 3em;
  text-transform: capitalize;
}
.hero-section .slogan h1 i {
  font-weight: 600;
}

@media screen and (max-width: 720px) {
  .hero-section .slogan h1 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .hero-section .slogan h1 {
    font-size: 2rem;
  }
}
