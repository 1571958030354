.get-consultation-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  color: #1c1d1d;
  padding: 7rem 7%;
  max-width: 2560px;
  margin: 0 auto;
  text-transform: capitalize;
  background: url('../../images/background/Logo\ Shadow.png') no-repeat left 10%
    bottom 0%;
  background-size: auto 100%;
}
.get-consultation-section h2 {
  font-size: 2rem;
  font-weight: 600;
}
.get-consultation-section .consultation-form {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 70%;
  align-items: center;
}
.get-consultation-section .consultation-form label {
  flex-grow: 1;
  margin: 0;
}

.get-consultation-section .consultation-form input {
  display: block;
  outline: none;
  border: 2px solid #1c1d1d;
  padding: 0.75rem 1.2rem;
  font: inherit;
  width: 100%;
  min-width: 240px;
  max-width: 720px;
}
.get-consultation-section .consultation-form button {
  align-self: flex-end;
  font: inherit;
  color: #fff;
  text-transform: capitalize;
  border: 0;
  padding: 0.75rem 2rem;
  border: 2px solid #1c1d1d;
  background-color: #1c1d1d;
  margin-left: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 1340px) {
  .get-consultation-section {
    align-items: flex-start;
    flex-direction: column;
  }
  .get-consultation-section .consultation-form {
    width: 100%;
  }
}
@media screen and (max-width: 840px) {
  .get-consultation-section .consultation-form {
    flex-direction: column;
    align-items: flex-start;
  }
  .get-consultation-section .consultation-form label {
    width: 70%;
  }
  .get-consultation-section .consultation-form button {
    align-self: flex-start;
    margin-left: 0;
  }
}
