.video-player {
  position: relative;
  width: 100%;
  height: 100%;
}
.video-player video {
  background-color: #000;
}
.video-player .video-controller {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-player .video-controller button {
  background: transparent;
  border: 0;
  width: 4rem;
  height: 4rem;
  text-align: center;
}
.video-player .video-controller button img {
  width: 100%;
  height: 100%;
}
.video-player .video-controller button:hover {
  cursor: pointer;
}
.video-player .video-controller.isplaying button {
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
}
.video-player:hover .video-controller.isplaying button {
  opacity: 1;
}
