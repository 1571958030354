.imaket-page-video .video-controller,
.imaket-features .video-controller {
  align-items: flex-end;
  justify-content: flex-end;
  padding: 2rem;
}
.imaket-page-video .video-controller button,
.imaket-features .video-controller button {
  width: 3rem;
  height: 3rem;
}

.imaket-page-description {
  width: 86%;
  max-width: 2560px;
  margin: 3rem auto;
  text-align: center;
  font-size: 1.25rem;
  text-transform: capitalize;
}

.imaket-page-description h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

.imaket-page-description p {
  margin: 0 auto;
  width: min(100%, 59rem);
}
@media screen and (max-width: 720px) {
  .imaket-page-description h1 {
    font-size: 2rem;
  }
  .imaket-page-description {
    font-size: 1rem;
  }
}

.imaket-features {
  font-size: 0.8rem;
}

@media (max-width: 540px) {
  .imaket-features .container {
    padding: 0;
  }

  .imaket-features .imaket-page-description {
    width: 100%;
  }
}
