.suli-compound-section {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.suli-info-container {
  --children-transition: opacity 0.7s ease-in-out;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 2rem;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 7%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0) 75% 100%
  );
}

.suli-info-container h2 {
  color: #fff;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: uppercase;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #fff;
  margin: 0;
}

.suli-info-container .title-link {
  text-decoration: none;
  opacity: 0;
  transition: var(--children-transition);
}

.suli-info-container .link-btn {
  color: #fff;
  border-color: #fff;
  opacity: 0;
  transition: var(--children-transition);
}
.suli-info-container .link-btn:hover {
  color: #ffc905;
  border-color: #ffc905;
}

.suli-info-container.inView .title-link,
.suli-info-container.inView .link-btn {
  opacity: 1;
}

@media screen and (max-width: 720px) {
  .suli-info-container h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .suli-info-container .link-btn {
    font-size: 1rem;
  }
}
@media screen and (max-width: 580px) {
  .suli-info-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 6rem;
    padding: 7%;
  }
}
