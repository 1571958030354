header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 3rem 0;
  background: linear-gradient(
    180deg,
    rgba(28, 29, 29, 0.25) 0%,
    rgba(28, 29, 29, 0) 100%
  );
}
header nav {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  width: 86%;
  margin: 0 auto;
}
header nav .nav-links {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
}
header nav ul {
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
header nav ul a,
header nav ul a:visited,
header nav ul a:active {
  color: #fff;
  text-decoration: none;
  transition: font-weight ease-in-out 200ms;
  text-transform: capitalize;
}
header nav ul a:hover,
header nav ul a.active {
  color: #ffc905;
  font-weight: 600;
  border-color: #ffc905;
}
header nav ul .link-btn {
  padding: 0.5rem 1rem;
  border: 2px solid #fff;
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
}
header nav .logo {
  vertical-align: middle;
}

.hamburger-btn {
  display: none;
  background-color: transparent;
  outline: none;
  border: 0;
  width: 2.5rem;
  cursor: pointer;
  color: #fff;
  margin: 0;
  text-align: center;
}
.hamburger-btn hr {
  border: 0;
  background-color: #fff;
  height: 2px;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 1080px) {
  header nav .nav-links {
    width: 90%;
  }
}

@media screen and (max-width: 880px) {
  header nav ul {
    position: fixed;
    right: 0;
    top: 0;
    margin: 0;
    opacity: 0;
    transform: translateX(100%);
  }
  .hamburger-btn {
    display: block;
  }
  header nav .show-sidebar ul {
    padding: 6rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3rem;
    width: 40%;
    min-width: 240px;
    height: 100vh;
    background-color: #1c1d1d;
    opacity: 1;
    transform: translate(0);
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
    transition-duration: 0.5s;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }
  header nav .show-sidebar .hamburger-btn {
    position: fixed;
    top: 3rem;
    right: 7%;
    z-index: 100;
  }
  header nav .show-sidebar .hamburger-btn hr:nth-child(1) {
    transform: rotate(-45deg);
    margin-bottom: -9px;
    margin-top: 7px;
  }
  header nav .show-sidebar .hamburger-btn hr:nth-child(2) {
    opacity: 0;
  }
  header nav .show-sidebar .hamburger-btn hr:nth-child(3) {
    transform: rotate(45deg);
    margin-bottom: 9px;
    margin-top: -7px;
  }
}

@media screen and (max-width: 720px) {
  header {
    padding-top: 1.5rem;
  }
  header nav {
    width: 90%;
  }
  header nav img {
    width: 180px;
  }
  header nav .show-sidebar .hamburger-btn {
    top: 1.5rem;
    right: 5%;
  }
}
@media screen and (max-width: 500px) {
  header nav img {
    width: 120px;
  }
}
