.imaket-section {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 2560px;
  padding: 3rem 7%;
  margin: 0 auto;
  gap: 2rem;
  grid-template-columns: minmax(0, 1fr) minmax(240px, 1fr);
  grid-template-rows: 0.3fr 1fr;
}
.imaket-section * {
  margin: 0;
}
.imaket-section .imaket-video {
  position: relative;
  width: 522px;
  max-width: 100%;
  height: 623px;
  border: 3.5rem solid rgba(28, 29, 29, 0.05);
  border-radius: 30rem 30rem 0 0;
  overflow: hidden;
  grid-column: 2;
  grid-row: 1/3;
  justify-self: end;
}

.imaket-section .imaket-heading {
  font-size: 2.5rem;
  align-self: center;
}
.imaket-section h2,
h3 {
  text-transform: capitalize;
}
.imaket-section .imaket-description h3 {
  font-weight: 300;
  font-size: 2rem;
}
.imaket-section .imaket-description h3 span {
  font-weight: 500;
}
.imaket-section .imaket-description p {
  text-transform: uppercase;
  width: 90%;
}
.imaket-section .imaket-description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}
.imaket-section .imaket-description a {
  margin-top: 1rem;
}

@media screen and (max-width: 1180px) {
  .imaket-section .imaket-video {
    width: 450px;
    height: 537px;
    border: 2.5rem solid rgba(28, 29, 29, 0.05);
  }
}
@media screen and (max-width: 890px) {
  .imaket-section > * {
    grid-column: 1/3;
  }
  .imaket-section .imaket-video {
    grid-column: 1/3;
    grid-row: 2;
    justify-self: center;
  }
  .imaket-section .imaket-description {
    grid-row: 3;
  }
}
@media screen and (max-width: 550px) {
  .imaket-section .imaket-video {
    height: 480px;
  }
}
