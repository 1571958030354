.our-projects {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #faf9f9;
  padding: 3rem 7%;
  gap: 4rem;
  z-index: 1;
}

.our-projects::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('../../images/background/Logo\ Outline.png') no-repeat top;
  background-size: auto 100%;
  z-index: -1;
  opacity: 0.2;
}

.our-projects h2 {
  font-size: 4rem;
  font-weight: 300;
  margin: 0;
}
.our-projects .projects-container {
  display: flex;
  width: 100%;
  max-width: 1920px;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

@media screen and (max-width: 1250px) {
  .our-projects .projects-container {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .our-projects h2 {
    font-size: 3rem;
  }
}
