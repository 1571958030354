footer {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  background-color: #1c1d1d;
  padding: 6.25rem 7% 2.5rem;
  color: #fff;
}
footer * {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
footer .footer-logo {
  width: 20%;
  min-width: 180px;
}
footer .footer-logo img {
  width: 100%;
}
footer a {
  color: #fff;
  text-decoration: none;
  overflow: hidden;
  line-height: 1rem;
}
footer .upper-footer .footer-contact-info a {
  font-size: 0.875rem;
}
footer .upper-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4rem;
}
footer .upper-footer section {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
footer .upper-footer section h2 {
  font-size: 1.4rem;
  font-weight: 500;
}
footer .upper-footer section ul {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  list-style: none;
}
footer .upper-footer section ul li {
  display: flex;
  gap: 1rem;
  align-items: center;
}

footer .auther-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 2rem;
}
.social-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.social-links img {
  width: 24px;
  height: 24px;
}
